
.media-article {
    @include screen-768 {
        // display: flex;
        // gap: 27px;
    }

    @include screen-768 {
        // gap: 45px;
        display: flex;
        flex-wrap: wrap;
    }

    @supports(display: grid) {
        @include screen-768 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            column-gap: 27px;
        }
        @include screen-992 {
            column-gap: 45px;
        }
    }

    &__heading {
        display: block;
        line-height: normal;
        margin-bottom: 3.6rem;

        @include screen-560 {
            text-align: center;
        }

        @include screen-768 {
            flex: 0 0 100%;
        }

        @include screen-992 {
            margin-bottom: $fs-body*4;
        }

        @include screen-1400 {
            margin-bottom: 27px;
            text-align: left;
            padding-left: 21px;
        }

        @supports(display: grid) {
            @include screen-768 {
                grid-column: span 2;
                grid-row: 1 / 2;
            }

            @include screen-1400 {
                grid-column: 2 / 3;
            }
        }
    }

    &__title {
        @include screen-1400 {
            font-size: $fs-body*4.8;
        }
    }

    &__media {
        background-color: $color-grey-01;
        margin-bottom: 21px;

        @include screen-768 {
            margin-bottom: 0;
        }

        @include screen-992 {
            flex: 0 0 50%;
        }

        @supports(display: grid) {
            @include screen-992 {
                grid-column: 1;
                grid-row: 2 / 3;
            }

            @include screen-1400 {
                grid-column: 1;
                grid-row: 1 / 3;
            }
        }
    }

    &__content {
        @include screen-992 {
            flex: 0 0 50%;
            display: flex;
            flex-direction: column;

            @supports(display: grid) {
                grid-column: 2;
                grid-row: 2 / 3;
            }
        }

        @include screen-1400 {
            padding-left: 21px;
        }

        @include screen-1200 {
            justify-content: center;
            line-height: 1.8;
        }

        @include screen-1400 {
            @supports(display: grid) {
                grid-column: 2;
                grid-row: 2 / 3;
            }
        }
    }

    img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__cta {
        margin-bottom: 0;

        @include screen-1200 {
            margin-top: $fs-body*1.5;
        }

        @include screen-1400 {
            margin-top: auto;
        }

        a {
            @include text-button;
        }
    }
}

.js .js-animate-section {
    .media-article__media {
        @include screen-768 {
            opacity: 0;
            transform: translate(-30px, 30px);
            transition: opacity 300ms ease-in-out,
                transform 300ms ease-in-out;
        }
    }

    .media-article__heading {
        @include screen-768 {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 300ms ease-in-out,
                transform 300ms ease-in-out;
        }

        @include screen-1400 {
            transform: translate(30px, 30px);
        }
    }

    .media-article__content {
        @include screen-768 {
            opacity: 0;
            transform: translate(30px, 30px);
            transition: opacity 300ms ease-in-out,
            transform 300ms ease-in-out;
        }
    }

    &.animate-section {
        .media-article__media {
            @include screen-768 {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
        .media-article__heading {
            @include screen-768 {
                opacity: 1;
                transform: translateY(0);
            }

            @include screen-1400 {
                transform: translate(0, 0);
            }
        }

        .media-article__content {
            @include screen-768 {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
}
