.testimonial-gallery {
	z-index: 1;

	&:after {
		z-index: -1;
	}

	&__row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@include screen-1200 {
			margin-left: -22.5px;
			margin-right: -22.5px;
		}
	}

	&__video {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 45px;

		@include screen-1200 {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 22.5px;
			padding-right: 22.5px;
			margin-bottom: 0;
		}

		video {
			box-shadow: 0 2px 36px rgba($color-black, 0.15), 0 2px 18px rgba($color-black, 0.15);
			border-radius: 3px;

			@include screen-1200 {
				object-fit: cover;
			}
		}
	}

	&__slider {
		flex: 0 0 100%;
		max-width: 100%;
		position: relative;

		@include screen-1200 {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 22.5px;
			padding-right: 22.5px;
		}

		&__wrapper {
			background-color: $color-white;
			box-shadow: 0 2px 36px rgba($color-black, 0.15), 0 2px 18px rgba($color-black, 0.15);
			border-radius: 3px;
			padding: 27px;
			text-align: center;
			height: 100%;

			@include screen-560 {
				padding: 45px;
			}
		}

		&__title {
			font-size: $fs-body*2;
		}

		.testimonials__slider {
			position: static;
			width: 100%;

			@include screen-480 {
				width: 100%;
			}

			.splide__track {
				overflow: hidden;
			}
		}
	}

	.testimonials__arrow.splide__arrow--prev {
		left: 0;
		right: auto;

		@include screen-480 {
			justify-content: center;
		}

		@include screen-768 {
			justify-content: flex-end;
		}
	}

	.testimonials__arrow.splide__arrow--next {
		left: auto;
		right: 0;

		@include screen-480 {
			justify-content: center;
		}

		@include screen-768 {
			justify-content: flex-start;
		}
	}

	.testimonial {
		&__item {
			scroll-snap-align: center;
			padding-top: 0;
			padding-bottom: 0;

			@include screen-768 {
				flex: 0 0 100%;
			}
		}

		&__box {
			background-color: transparent;
			box-shadow: none;
			border-radius: 0;
			padding: 9px;

			@include screen-768 {
				padding: 0 36px;
				flex: 1 1 auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			@include screen-992 {
			}

			@include screen-1200 {
				padding: 0 24px;
			}

			@include screen-1400 {
				padding: 0 36px;
			}

			&:before,
			&:after {
				content: none;
			}
		}

		&__quote {
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;

			line-height: 1.5;
			max-height: calc(6 * 1.5em);

			@include screen-768 {
				-webkit-line-clamp: 5;
				max-height: calc(5 * 1.5em);
			}
		}
	}
}


.js {
	.testimonial-gallery {
		.testimonial__box {
			opacity: 1;
			transform: scale(1);
		}

		.testimonial__item {
			.testimonial__box {
				margin-right: 0;
				margin-left: 0
			}
		}

		.testimonial__item.active {
			.testimonial__box {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.testimonial__item.active + .testimonial__item {
			.testimonial__box {
				margin-left: 0;
				margin-right: 0
			}
		}
	}
}
