.listing-page__introduction {
    margin-bottom: $fs-body*1.8;

    @include screen-560 {
        margin-bottom: $fs-body*2.4;
        text-align: center;
    }

    @include screen-992 {
        margin-bottom: $fs-body*4;
    }

    &.long {
        @include screen-992 {
            columns: 2;
            text-align: left;
            column-gap: 40px;

            p {
                break-inside: avoid;
            }
        }

        @include screen-1200 {
            column-gap: 56px;
            max-width: 1080px;
            margin-left: auto;
            margin-right: auto;
        }

        @include screen-1600 {
            column-gap: 64px;
            margin-bottom: $fs-body*4.8;
        }
    }

    &.short {
        @include screen-992 {
            margin-left: auto;
            margin-right: auto;
            max-width: 640px;
        }
    }
}
