.tile {
    background: $color-white;

    &__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__image {
        position: relative;
        margin-bottom: 21px;

        img {
            width: 100%;
        }
    }

    &__subtitle {
        display: inline-block;
        position: absolute;
        top: 21px;
        left: -15px;

        @include screen-992 {
            left: -21px;
        }
    }

    &__heading {
        margin-bottom: $fs-body;
        color: $color-primary;
        line-height: normal;

        @include screen-992 {
            font-size: $fs-body*1.2;
        }

        @include screen-1200 {
            font-size: $fs-body*1.4;
        }
    }

    &__description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include screen-768 {
            -webkit-line-clamp: 5;
        }

        @include screen-992 {
            font-size: $fs-body;
        }

        @include screen-1200 {
            overflow: auto;
            text-overflow: initial;
            -webkit-line-clamp: unset;
        }
    }

    &__cta {
        margin-top: auto;

        a {
            @include text-button;
        }
    }
}

.tile:nth-child(3n) .tile__subtitle {
    @include subtitleBlock($color-00);
}

.tile:nth-child(3n + 1) .tile__subtitle {
    @include subtitleBlock($color-01);
}

.tile:nth-child(3n + 2) .tile__subtitle {
    @include subtitleBlock($color-02);
}

.js .js-animate-section {
    .tile {
        @media screen and (min-width: $bp768) and (orientation : landscape) {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 500ms ease-in-out,
                transform 500ms ease-in-out;
        }
    }

    .tile__subtitle {
        @media screen and (min-width: $bp768) and (orientation : landscape) {
            opacity: 0;
            transform: translateX(-30px);
            transition: opacity 350ms 250ms ease-in-out,
                transform 350ms 250ms ease-in-out;
        }
    }

    .tile__cta {
        @media screen and (min-width: $bp768) and (orientation : landscape) {
            opacity: 0;
            transform: translateX(-30px);
            transition: opacity 350ms 500ms ease-in-out,
                transform 350ms 500ms ease-in-out;
        }
    }

    &.animate-section {
        .tile {
            opacity: 1;
            transform: translateY(0px);
        }

        .tile__subtitle {
            opacity: 1;
            transform: translateX(0px);
        }

        .tile__cta {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}
