$title-value-spacing: 9px;

.title-value-item {
    margin-top: $title-value-spacing;
    margin-bottom: $title-value-spacing;

    &__title {
        font-size: 75%;
        display: block;
        line-height: 1;
        font-weight: $fw-bold;
        text-transform: uppercase;

        .field-helper {
            text-transform: none;
        }
    }

    &__value {
        display: block;
        line-height: normal;
        padding-left: $title-value-spacing;
        font-weight: $fw-regular;
    }
}

p.title-value-item__title {
    margin-bottom: 0.25em;
}

