$banner-text-peak: 130px;

.home-banner {
    display: block;
    background-color: $color-white;

    @include screen-1200 {
        padding: 0 45px 45px;
    }
}

.home-banner__video {
    video {
        display: block;
    }

    @include screen-560 {
    }

    @include screen-1200 {
        /*
            header = 146px
            bottom-padding = 45px
            total = 191px
        */
        position: absolute;
        display: block;
        top: calc(100vh - ((100vh - 191px) - 429px + #{$banner-text-peak}));
        left: 45px;
        right: 45px;
        width: calc(100vw - 90px);
        height: calc((100vw - 90px) * 0.5625);
        max-height: calc(100vh - 191px);
        overflow: hidden;
    }

    @media screen and (min-width: 100em) {/* 1600px */
        /*
        header = 162px
        bottom-padding = 45px
        total = 207px
        */
        top: calc(100vh - ((100vh - 207px) - 429px + #{$banner-text-peak}));
        max-height: calc(100vh - 207px)
    }

    @media screen and (min-width: 105.625em) {/* 1690px */
        width: calc(#{$container-max} + 90px);
        left: calc(50vw - 753px - 45px);
        right: calc(50vw - 753px - 45px);
    }
}

.html-video-fixed .home-banner__video {
    @include screen-1200 {
        top: 146px;
        position: fixed;
    }
}

.home-banner__video video {
    object-fit: cover;
}

.home-banner__copy {
    display: block;

    @include screen-1200 {
        margin-top: min((((100vw - 90px) * 0.5625) + #{$header-height-desktop} - #{$banner-text-peak}), (100vh - 45px - #{$banner-text-peak}));
        padding-bottom: 45px;
        padding-left: 45px;
        position: relative;
        z-index: 1;
    }

    @media screen and (min-width: 105.625em) {/* 1690px */
        padding-left: calc(50vw - 753px - 45px);
    }

    @media screen and (min-width: 125em) {/* 2000px */
        padding-left: #{$header-height-desktop};
    }

    &__inner {
        background-color: $color-grey-01;
        padding-top: 6vw;

        @include screen-560 {
            padding-left: 6vw;
            padding-right: 6vw;
            padding-bottom: 6vw;
        }

        @include screen-768 {
            padding: 27px 45px 45px;
        }

        @include screen-1200 {
            background-color: $color-02;
            color: $color-white;
            display: flex;
            flex-direction: column;
            box-shadow: -3px 3px 21px rgba(0,0,0,0.25);
            height: 429px;
            width: 933px;
            padding-top: 45px;
        }
    }

    &__title {
        @include title-display;
        font-weight: $fw-light;
        margin-top: 0;
        margin-bottom: $fs-body;
        line-height: normal;
        padding-left: 6vw;
        padding-right: 6vw;

        @include screen-560 {
            padding-left: 0;
            padding-right: 0;
        }

        @include screen-1200 {
            color: $color-white;
        }
    }

    &__description {
        padding-left: 6vw;
        padding-right: 6vw;
        margin-bottom: 1.5em;

        @include screen-560 {
            padding-left: 0;
            padding-right: 0;
        }

        @include screen-992 {
            font-size: $fs-body*1.4;
        }
    }

    &__cta {
        margin-top: auto;
        margin-bottom: 0;
        display: flex;

        @include screen-560 {
            display: block;
        }

        a {
            flex: 0 0 50%;

            @include screen-560 {
                flex: 0 0 auto;
            }
        }

        a:first-child {
            @include button;
            @include button-height-lg;
            padding-left: 12px;
            padding-right: 12px;
            font-size: 1.3rem;

            @include screen-360() {
                @include button-text-reg;
            }

            @include screen-560 {
                @include button-width-reg;
            }

            @include screen-992 {
                @include button-width-lg;
                @include button-text-lg;
            }
        }

        a:last-child {
            @include text-button($color-00, $color-primary);
            @include button-height-lg;
            padding-left: 12px;
            padding-right: 12px;
            font-size: 1.3rem;

            span span:nth-last-child(1n + 2) {
                display: none;

                @include screen-768() {
                    display: inline;
                }
            }

            @include screen-360() {
                @include button-text-reg;
            }

            @include screen-560 {
                @include button-width-reg;
            }

            @include screen-992 {
                margin-left: 18px;
                @include button-text-lg;
            }

            @include screen-1200 {
                @include text-button($color-white, $color-03);
            }
        }
    }
}
