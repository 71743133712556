.image-gallery {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;

	@supports(display: grid) {
		display: grid;
		gap: 8px;
		grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)) ;

		@include screen-360 {
			grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)) ;
		}

		@include screen-560 {
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) ;
		}

		&.-clearfix:before,
		&.-clearfix:after {
			content: none;
		}
	}
}
.image-gallery-thumb {
	width: 100%;
	display: block;
	background-color: $color-grey-01;

	@supports(aspect-ratio: 4/3) {
		aspect-ratio: 4/3;
	}
}

.image-gallery-caption {
	margin-top: $spacing--xsmall;
}

.image-gallery-popup {
	display: block;
}

.image-gallery-thumb img {
	width: 100%;
	background: rgba($color-black, 0.25);
}

.image-gallery--captions {
	display: flex;
	flex-flow: row wrap;
}

.js--popup-gallery {
	position: relative;

	&:before,
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 32px;
		height: 32px;
		background-color: transparent;
	}

	&:before {
		background: rgba($color-black, 0.5);
	}

	&:after {
		background-image: url(../assets/icon_magnify.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 14px;
	}
}

