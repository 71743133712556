.quote-form {
    max-width: 1200px;
    margin: 0 auto;

    .quote-form__header {
        &.field-element {
            @include screen-560 {
                align-items: flex-start;
            }
        }
    }

    &__title {
        display: block;
        font-size: $fs-body*1.5;
        text-transform: uppercase;
        letter-spacing: 0.25em;
        font-weight: normal;
        line-height: normal;
        margin-bottom: 0.5em;
        color: $color-primary;

        @include screen-480 {
            font-size: $fs-body*1.8;
        }

        @include screen-768 {
            font-size: $fs-body*1.8;
        }

        @include screen-992 {
            font-size: $fs-body*2.4;
        }
    }

    &__back-button {
        margin-top: -1.5em;
        margin-bottom: 2.5em;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        @include button-grey;

        &:hover,
        &:focus,
        &:active {
            box-shadow: 0 4px 12px rgba(0,0,0,0.25);
        }

        @include screen-560 {
            margin-top: 0;
            margin-bottom: 0;
        }

        &:before {
            content: "\003c\00a0";
        }

        &:after {
            content: none;
        }
    }

    .form__header {
        margin-top: $fs-body*2;

        @include screen-768 {
            padding-left: 300px;
        }

        @include screen-992 {
            padding-left: calc(50% - 90px);
        }

        @include screen-1200 {
            padding-left: 400px;
        }
    }

    .product__field {
        margin-bottom: 45px;

        @include screen-768 {
            margin-bottom: 63px;
        }
    }

    .field-element {
        @include screen-560 {
            display: flex;
            align-items: center;
            margin-left: -5px;
            margin-right: -5px;
        }

        > * {
            @include screen-560 {
                padding-left: 5px;
                padding-right: 5px;
                flex: 1 1 auto;
                max-width: 100%;
            }

            &:first-child {
                @include screen-560 {
                    flex: 0 0 33.3333%;
                }

                @include screen-768 {
                    flex: 0 0 300px;
                }

                @include screen-992 {
                    flex: 0 0 calc(50% - 90px);
                }

                @include screen-1200 {
                    flex: 0 0 400px;
                }
            }
        }

        .field-label {
            @include screen-560 {
                margin-bottom: 0;
                text-align: right;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            .field-label__required {
                text-align: left;
            }

            .instructions {
                flex: 0 0 100%;
                font-weight: $fw-regular;
                margin-bottom: 0;
            }
        }
    }

    .field-element--button {
        display: flex;

        > *:last-child {
            flex: 1 1 100%;

            .button {
                display: block;
                width: 100%;
            }
        }

        .quote-form__back-button {
            margin-top: 0;
            margin-bottom: 0;
        }
    }


    .tab {

    }
}
