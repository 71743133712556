.cta-box {
    margin-bottom: 32px;

    &__heading {
        margin-top: 0;
    }

    &__subtitle {
        position: absolute;
        left: -15px;
        top: 0;
        @include subtitleBlock($color-01, true);
    }

    &__title {
        display: block;
        position: relative;
        padding-top: 36px;
        font-size: $fs-body*1.5;
        margin-bottom: $fs-body*0.8;
        color: $color-grey-08;

        @include screen-1400 {
            font-size: $fs-body*2;
            margin-bottom: $fs-body;
        }
    }

    &__cta {
        a {
            @include text-button;
        }
    }

    &__content {

        > *:last-child {
            margin-bottom: 0;
        }
    }
}
