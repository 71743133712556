.gallery {
    transition:
        padding-top 300ms ease-in-out;

    @include screen-1200 {
        display: flex;
    }

    &__slider {
        position: relative;
        padding-bottom: 54px;

        &__track {
            width: 100%;
            display: flex;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
            scrollbar-width: none;
	        -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
                width: 0;
            }
        }
    }

    .slider__dots {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 24px;
        z-index: 1;
        display: none;

        @media screen and (min-width: 25em) {
            display: flex;
        }

        a {
            padding: 12px;
            position: relative;

            span {
                @include vis-hidden;
            }

            &:before {
                content: "";
                background-color: transparent;
                border: 2px solid $color-grey-04;
                height: 10px;
                width: 10px;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition:
                    background-color 200ms ease,
                    box-shadow 200ms ease;
            }

            &:hover:before {
                background-color: $color-grey-06;
                border-color: $color-grey-06;
            }

            &:focus:before {
                background-color: $color-grey-06;
                border-color: $color-grey-06;
            }

            &.active:before {
                background-color: $color-grey-06;
                border-color: $color-grey-06;
                transition-delay: 50ms;
            }
        }
    }

    .slider__arrows {
        position: absolute;
        top: calc(50% - 18px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        white-space: normal;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        background: transparent;
        text-decoration: none;
        border-radius: 0;
        border: none;
        outline: none;
        opacity: 1;
        padding: 0;
        transition:
            background-color 250ms ease-in-out,
            color 250ms ease-in-out,
            border-color 250ms ease-in-out;
        width: 36px;
        height: 36px;

        @include screen-480 {
            width: 54px;
            height: 54px;
            top: calc(50% - 27px);
        }

        &.previous {
            right: 100%;

            &:hover {
                svg {
                    transform: scale(1.33) translateX(-3px);
                }
            }
        }

        &.next {
            left: 100%;

            &:hover {
                svg {
                    transform: scale(1.33) translateX(3px);
                }
            }
        }

        &:focus svg path {
            fill: $color-black;
        }

        * {
            pointer-events: none;
        }

        svg {
            transform: scale(1) translateX(0);
            transition: transform 250ms ease-in-out;

            @include screen-480 {
                width: 10px;
                height: 12px;
            }
        }
    }

    &__slide {
        display: block;
        width: 50%;
		flex-shrink: 0;
		scroll-snap-align: start;
        padding: 0 9px 0 0;

        @include screen-560 {
            padding: 0 27px 0 0;
        }

        @include screen-768 {
            width: 25%;
            padding: 0 45px 0 0;
        }

        @include screen-992 {
            width: 20%;
        }

        @include screen-1200 {
            width: 16.66666666%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:after,
        &:before {
            right: 9px;

            @include screen-560 {
                right: 27px;
            }

            @include screen-768 {
                right: 45px;
            }
        }

        &__picture {
            display: block;
            height: 100%;
        }

        &:first-child {
            padding-left: 9px;

            @include screen-560 {
                padding-left: 27px;
            }

            @include screen-768 {
                padding-left: 45px;
            }
        }
    }
}
