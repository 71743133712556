
.card {
    background: rgba($color-grey-02, 0.666);
    color: rgba($color-primary, 0.8);
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: color 200ms ease,
        box-shadow 200ms ease;


    &:hover {
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
        color: $color-primary;

        .card__picture {
            opacity: 0.75;
        }

        .card__copy {
            // text-shadow: -1px 1px 0 rgba($color-white, 1), 0 0 12px rgba($color-white, 0.25);
        }

        .card__cta {
            @include screen-1200 {
                display: block;
                animation: fadeInUp 400ms ease-in-out;
                animation-fill-mode: both;
            }
        }

    }

    &:focus {
        outline: 1px dashed $color-00;
        outline-offset: 3px;

        .card__picture {
            opacity: 0.66;
        }
    }

    &:focus-within {
        box-shadow:0 0 2px 0 rgba(0, 0, 0, 0.33)
    }

    &__item {
        width: 100%;
        overflow: hidden;
        display: block;
    }

    &__content {
        display:flex;
        flex-direction:column;
        flex-grow: 1;
        position: relative;
    }

    &__description {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 100%;
    }

    &__heading {
        font-family: $accent-font;
        font-weight: $fw-light;
        font-size: $fs-body*1.8;
        line-height: 1.5;
        color: $color-primary;

        @include screen-560 {
            font-size: $fs-body*2.4;
        }

        @include screen-1200 {
            font-size: $fs-body*4.8;
        }

        span:nth-child(1n + 2) {
            display: none;

            @include screen-560 {
                display: inline;
            }
        }
    }

    &__copy {
        display: none;
        transition: text-shadow 200ms ease-in-out;

        @include screen-768 {
            display: block;
            padding-left: 27px;
            padding-right: 27px;
            font-size: $fs-body*0.875;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-shadow: -1px 1px 0 rgba($color-white, 0), 0 0 12px rgba($color-white, 0);
        }

        @include screen-992 {
            overflow: auto;
            text-overflow: initial;
            -webkit-line-clamp: unset;
        }
    }

    &__cta {
        margin-top: 5px;
        line-height: 1.5;
        @include text-button;

        @include screen-1200 {
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
        }
    }

    &__picture {
        opacity: 0.5;
        transition: opacity 200ms ease;

        @supports( aspect-ratio: 9/16 ) {
            aspect-ratio: 9/16;

            @include screen-992 {
                aspect-ratio: 103/120;
            }
        }

        @include screen-768 {
            width: 50%;
        }
    }

    &__image {
        @supports( aspect-ratio: 9/16 ) {
            aspect-ratio: 9/16;
            object-fit: cover;

            @include screen-992 {
                aspect-ratio: 103/120;
            }
        }
    }
}

.show-title .card__heading {
    line-height: 1.2;
    margin-bottom: 0.3em;

    @include screen-560 {
        line-height: 1.5;
    }

    span:nth-child(1n + 2) {
        display: block;

        @include screen-560 {
            display: inline;
        }
    }
}

.js .js-animate-section {
    .card__item {
        @media screen and (min-width: $bp768) {
            opacity: 0;
            transition: opacity 500ms ease-in-out,
                transform 500ms ease-in-out;
        }
        &:nth-child(n + 3) {
            @media screen and (min-width: $bp768) {
                transition-delay: 500ms;
            }
        }
        &:nth-child(odd) {
            @media screen and (min-width: $bp768) {
                transform: translate(-30px, 30px);
            }
        }
        &:nth-child(even) {
            @media screen and (min-width: $bp768) {
                transform: translate(30px, 30px);
            }
        }
    }

    &.animate-section {
        .card__item {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}
