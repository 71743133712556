.icon-card__list {

    @include screen-768 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -9px;
        margin-right: -9px;
    }

    @include screen-992 {
        margin-left: -18px;
        margin-right: -18px;
    }

    @include screen-1200 {
        margin-left: -27px;
        margin-right: -27px;
    }
}

.icon-card__item {
    margin-bottom: 45px;
    margin-left: -6vw;

    @include screen-560 {
        margin-left: 0;
    }

    @include screen-768 {
        flex: 0 0 33.333%;
        padding-left: 9px;
        padding-right: 9px;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }

    @include screen-992 {
        padding-left: 18px;
        padding-right: 18px;
    }

    @include screen-1200 {
        padding-left: 27px;
        padding-right: 27px;
    }
}


.icon-card {
    display: block;
    background-color: $color-white;
    border-left: 6vw solid $color-00;
    padding-left: 36px;
    padding-top: 9px;
    padding-bottom: 9px;

    @include screen-560 {
        text-align: center;
        box-shadow: 0 2px 36px rgba($color-black, 0.15), 0 2px 18px rgba($color-black, 0.15);
        border-radius: 3px;
        border-left: none;
        padding: 45px;
    }

    @include screen-768 {
        padding: 27px;
        padding-top: 40px;
        flex: 1 1 auto;
    }

    @include screen-1200 {
        padding: 45px;
    }

    &__icon {
        border: 2.75px solid $color-grey-04;
        width: 144px;
        height: 144px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include screen-560 {
            margin: 0 auto;
        }

        @include screen-768 {
            width: 81px;
            height: 81px;
        }

        @include screen-992 {
            width: 144px;
            height: 144px;
        }

        svg {
            display: block;
            margin: 0 auto;
            width: 81px;
            height: 81px;

            @include screen-768 {
                width: 45px;
                height: 45px;
            }

            @include screen-992 {
                width: 81px;
                height: 81px;
            }

            path,
            rect,
            line,
            circle {
                stroke: $color-grey-04;
                stroke-width: 0.66;

                @include screen-768 {
                    stroke-width: 1;
                }

                @include screen-992 {
                    stroke-width: 0.66;
                }
            }
        }
    }

    &__title {
        color: $color-00;
        margin-top: $fs-body;
        margin-bottom: $fs-body;

        @include screen-768 {
            font-size: $fs-body*1.2;
            margin-bottom: $fs-body*0.8;
        }

        @include screen-992 {
            font-size: $fs-body*1.4;
            margin-bottom: $fs-body;
        }
    }

    &__excerpt {
        margin-bottom: 0;

        @include screen-768 {
            font-size: $fs-body*0.875;
        }

        @include screen-992 {
            font-size: $fs-body;
        }

    }
}
