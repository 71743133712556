.inner-banner {
    display: block;
    background-color: $color-white;
    max-width: calc(#{$container-max} + 90px);
    margin: 0 auto;
    position: relative;

    @include screen-1200 {
        padding: 45px 45px 0;
    }

    &__img {
        display: block;
        position: relative;
        width: 100%;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba($color-black, 0.5);
        }
    }

    &__content {
        position: absolute;
        color: $color-white;
        z-index: 1;
        left: 6vw;
        right: 6vw;
        bottom: 6vw;

        @include screen-768 {
            left: 4vw;
            right: 4vw;
            bottom: 4vw;
        }

        @include screen-1600 {
            left: 0;
            right: 45px;
            bottom: 45px;
        }

        .section-header__title {
            color: $color-white;
            line-height: 1.05;

            @include screen-560 {
                font-size: $fs-body*2.4;
            }

            @include screen-768 {
                font-size: $fs-body*4;
                line-height: 1;
            }

            @include screen-992 {
                font-size: $fs-body*4;
            }

            @include screen-1200 {
                font-size: $fs-body*6;
            }

            &.long-title {
                line-height: 1;

                @include screen-1200 {
                    font-size: $fs-body*4;
                }

                @include screen-1400 {
                    line-height: inherit;
                    font-size: $fs-body*6;
                }
            }
        }

        .breadcrumb {
            color: rgba($color-white, 0.75);

            a {
                color: $color-white;

                &:before {
                    background-color: $color-primary;
                }

                &:hover,
                &:focus {
                    color: $color-white;
                }
            }
        }
    }

    &.inner-banner--sidebar {
        .inner-banner__content {
            @include screen-992 {
                left: calc(((100vw - 8vw) * 0.33333333) + (2.555555vw * 2));
            }

            @include screen-1600 {
                left: calc((#{$container-max} * 0.33333333) + 80px);
            }
        }

        .breadcrumb {
            justify-content: flex-start;
        }

        .section-header {
            text-align: left;
        }
    }
}
