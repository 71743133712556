.tile-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 45px;

    @include screen-768 {
        gap: 36px;
    }

    @include screen-992 {
        gap: 45px;
    }

    @include screen-1200 {
        flex-wrap: nowrap;
    }

    .tile {
        flex: 0 0 100%;
        max-width: 100%;

        @include screen-768 {
            flex: 0 1 50%;
            max-width: calc(50% - 18px);
        }

        @include screen-992 {
            max-width: calc(50% - 23px);
        }

        @include screen-1200 {
            flex: 0 1 33.333%;
            max-width: calc(33.333% - 15px);
        }
    }
}

