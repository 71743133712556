/* ---- lazy image loading ---- */

.filtered-list {
    .item-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    &.unfiltered .item-hidden,
    &.filtered .filtered-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    &.unfiltered .filter-listing__item--animate {
        animation: fadeIn 300ms ease;
        animation-fill-mode: forwards;
    }

    &.filtered .filter-listing__item--filtered-pre-visible {
        opacity: 0;
    }

    &.unfiltered .filter-listing__item--animate-grow,
    &.filtered .filter-listing__item--filtered-visible {
        animation: growInFade 350ms ease;
        animation-fill-mode: forwards;
    }
}


/* ------------------------------------------------------
**** Category lists
------------------------------------------------------ */

.filter-list__filters {
    padding: 0;
    margin-bottom: $fs-body;
    text-align: center;
}

.field-element.filter-list__dropdown {
    margin-bottom: 35px;

    @include screen-560 {
        margin-bottom: 0;
    }
}

.js-filters-list .js-filter-category-btn__select {
    margin-bottom: 24px;
}

/* Hides the list if js is working, defaults to working list if not */
@media screen and (max-width: $bp559) {
    .js .js-filters-list .filter-list__filters {
        display: none;
    }
}

@include screen-560() {
    .filter-list__filters {
        .button {
            border-radius: 0;;
            margin: 2px;

            &.button-grey {
                background-color: $color-grey-04;
                border-color: $color-grey-04;
                color: $color-white;

                &:hover,
                &:focus {
                    background-color: $color-grey-09;
                    border-color: $color-grey-09;
                    color: $color-00;
                }
            }
        }

        &__category-group {
            margin: 0 -3px -6px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .filter-list__button {
                @include button;
                padding: 6px 12px;
                margin-left: 3px;
                margin-right: 3px;
                margin-bottom: 6px;

                &:after {
                    content: none;
                }

                &.button-grey {
                    background-color: $color-grey-02;
                    color: $color-primary;
                }
            }
        }
    }

    .js-filter-category-btn__select {
        display: none !important;
    }
}

.filter-list__filters__title {
    @include subtitle;
    margin-bottom: 0;
    margin-top: 0;
}

* + .filter-list__filters__title {
    margin-top: $fs-body*0.5;
}

.filter-list__filters__item {
    @include screen-560 {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        max-width: 100%;
    }
    @include screen-992 {
        flex-grow: 0;
    }

    .button {
        display: block;
        width: calc(100% - 4px);
    }
}


/* Grow in fade */
@-webkit-keyframes growInFade {
	from {
		opacity: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		        transform: none;
	}
}
@keyframes growInFade {
	from {
		opacity: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		        transform: none;
	}
}

