#header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height-desktop;
    background-color: #fff;
    z-index: 99;
    transition:
        height $link-transition;

    @media screen and (min-width: 125em) {/* 2000px */
        max-width: 1920px;
        margin: 0 auto;
    }
}

.section--header {
    background: $color-white;
    padding: 9px;
    box-shadow: 0 2px 32px rgba(0,0,0,0.25);
    transition:
        box-shadow 200ms ease,
        padding $link-transition;

    @include screen-992 {
        padding: 18px;
    }

    > .container {
        width: auto;

        @include screen-992 {
            width: 92%;
        }
    }
}

.home .section--header {
    box-shadow: 0 0 32px rgba(0,0,0,0);
}

.header__navigation {
    padding-right: 62px;
    display: flex;
    justify-content: flex-end;

    @include screen-992() {
        padding-right: 0;
        display: block;
    }

    svg {
        opacity: 0.75;
    }
}

.header__logo a {
    display: block;

    img {
        height: 45px;
        width: auto;
        transition:
            height $link-transition;

        @include screen-992() {
            height: 108px;
        }
    }
}

.header__search .field-element {
    margin-bottom: 0
}

.header__mobile-link {
    display: flex;
    align-items: center;
    @include subtitle;
    color: $color-grey-07;
    text-decoration: none;
    margin-right: $fs-body;

    svg {
        margin-right: 7px;
    }

    @include screen-992() {
        display: none;
    }

    &__label {
        @include vis-hidden;

        @include screen-360() {
            @include vis-hidden-reset;
        }
    }
}


/** Mobile menu only
===================================*/
@media all and (max-width: $bp991) {
    #header {
        background-color: $color-white;
        height: 63px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            height: 63px;
            left: 0;
            right: 0;
            box-shadow: 0 0 27px rgba(0,0,0,0.2), 0 1px 15px rgba(0,0,0,0.15);
            z-index:-1;
        }
    }
}


.header-shrink {
    #header {
        @include screen-992 {
            height: 112px;
        }
    }

    .section--header {
        @include screen-992 {
            padding-top: 9px;
            padding-bottom: 0;
        }
    }

    .topnav {
        @include screen-992 {
            margin-top: 6px;
        }
    }

    .header__logo a img {
        @include screen-992 {
            height: 96px;
        }
    }
}
