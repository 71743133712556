.card__list {
    padding: 0;
    margin: 0;
    list-style: none;

    @supports (display: grid) {
        display: grid;
        grid-gap: 18px;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;

        @include screen-560 {
            grid-gap: 27px;
        }

        @include screen-992 {
            grid-gap: 45px;
        }
    }
}

