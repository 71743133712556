@charset "UTF-8";
/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/*
Basic system font stacks
*/
/* Poppins Weights: Regular: 400, Bold: 700 */
/* Lato Weights: Light: 300, Regular: 400 */
/*
Weights
*/
/*
Sizes
*/
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in down */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.highlight-pulse {
  animation: highlightPulse 700ms ease;
  animation-fill-mode: forwards;
}

.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ---- Mixins ---- */
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
/* Red Warning Button */
/* Light Grey */
/* Mid Grey */
/* Green */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* ------------------------------------------------------
**** Blocks
------------------------------------------------------ */
.date-card {
  background: #1D1E26;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding: 16px;
  max-width: 94px;
  line-height: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.date-card__day {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: bold;
}

.home-banner {
  display: block;
  background-color: #ffffff;
}
@media screen and (min-width: 75em) {
  .home-banner {
    padding: 0 45px 45px;
  }
}

.home-banner__video video {
  display: block;
}
@media screen and (min-width: 75em) {
  .home-banner__video {
    /*
        header = 146px
        bottom-padding = 45px
        total = 191px
    */
    position: absolute;
    display: block;
    top: calc(100vh - ((100vh - 191px) - 429px + 130px));
    left: 45px;
    right: 45px;
    width: calc(100vw - 90px);
    height: calc((100vw - 90px) * 0.5625);
    max-height: calc(100vh - 191px);
    overflow: hidden;
  }
}
@media screen and (min-width: 100em) {
  .home-banner__video {
    /* 1600px */
    /*
    header = 162px
    bottom-padding = 45px
    total = 207px
    */
    top: calc(100vh - ((100vh - 207px) - 429px + 130px));
    max-height: calc(100vh - 207px);
  }
}
@media screen and (min-width: 105.625em) {
  .home-banner__video {
    /* 1690px */
    width: calc(1506px + 90px);
    left: calc(50vw - 753px - 45px);
    right: calc(50vw - 753px - 45px);
  }
}

@media screen and (min-width: 75em) {
  .html-video-fixed .home-banner__video {
    top: 146px;
    position: fixed;
  }
}

.home-banner__video video {
  object-fit: cover;
}

.home-banner__copy {
  display: block;
}
@media screen and (min-width: 75em) {
  .home-banner__copy {
    margin-top: min((((100vw - 90px) * 0.5625) + 146px - 130px), (100vh - 45px - 130px));
    padding-bottom: 45px;
    padding-left: 45px;
    position: relative;
    z-index: 1;
  }
}
@media screen and (min-width: 105.625em) {
  .home-banner__copy {
    /* 1690px */
    padding-left: calc(50vw - 753px - 45px);
  }
}
@media screen and (min-width: 125em) {
  .home-banner__copy {
    /* 2000px */
    padding-left: 146px;
  }
}
.home-banner__copy__inner {
  background-color: #F0F4F6;
  padding-top: 6vw;
}
@media screen and (min-width: 35em) {
  .home-banner__copy__inner {
    padding-left: 6vw;
    padding-right: 6vw;
    padding-bottom: 6vw;
  }
}
@media screen and (min-width: 48em) {
  .home-banner__copy__inner {
    padding: 27px 45px 45px;
  }
}
@media screen and (min-width: 75em) {
  .home-banner__copy__inner {
    background-color: #734226;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    box-shadow: -3px 3px 21px rgba(0, 0, 0, 0.25);
    height: 429px;
    width: 933px;
    padding-top: 45px;
  }
}
.home-banner__copy__title {
  color: #1D1E26;
  font-size: 3.6rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 1.5rem;
  line-height: normal;
  padding-left: 6vw;
  padding-right: 6vw;
}
@media screen and (min-width: 35em) {
  .home-banner__copy__title {
    font-size: 6rem;
  }
}
@media screen and (min-width: 62em) {
  .home-banner__copy__title {
    font-size: 9rem;
  }
}
@media screen and (min-width: 35em) {
  .home-banner__copy__title {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 75em) {
  .home-banner__copy__title {
    color: #ffffff;
  }
}
.home-banner__copy__description {
  padding-left: 6vw;
  padding-right: 6vw;
  margin-bottom: 1.5em;
}
@media screen and (min-width: 35em) {
  .home-banner__copy__description {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 62em) {
  .home-banner__copy__description {
    font-size: 2.1rem;
  }
}
.home-banner__copy__cta {
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
}
@media screen and (min-width: 35em) {
  .home-banner__copy__cta {
    display: block;
  }
}
.home-banner__copy__cta a {
  flex: 0 0 50%;
}
@media screen and (min-width: 35em) {
  .home-banner__copy__cta a {
    flex: 0 0 auto;
  }
}
.home-banner__copy__cta a:first-child {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background: #1B244A;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
  padding: 12px 27px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 1.3rem;
}
.home-banner__copy__cta a:first-child:hover, .home-banner__copy__cta a:first-child:focus, .home-banner__copy__cta a:first-child:active {
  background-color: #0d1225;
  color: #ffffff;
}
.home-banner__copy__cta a:first-child svg path {
  fill: #ffffff;
}
.home-banner__copy__cta a:first-child:after {
  content: " >";
}
@media screen and (min-width: 22.5em) {
  .home-banner__copy__cta a:first-child {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 35em) {
  .home-banner__copy__cta a:first-child {
    padding-left: 27px;
    padding-right: 27px;
  }
}
@media screen and (min-width: 62em) {
  .home-banner__copy__cta a:first-child {
    padding-left: 45px;
    padding-right: 45px;
    font-size: 2.1rem;
  }
}
.home-banner__copy__cta a:last-child {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 1.3rem;
}
.home-banner__copy__cta a:last-child svg path {
  fill: #1B244A;
}
.home-banner__copy__cta a:last-child:hover, .home-banner__copy__cta a:last-child:focus, .home-banner__copy__cta a:last-child:active {
  color: #1D1E26;
}
.home-banner__copy__cta a:last-child:hover svg path, .home-banner__copy__cta a:last-child:focus svg path, .home-banner__copy__cta a:last-child:active svg path {
  fill: #1D1E26;
}
.home-banner__copy__cta a:last-child:after {
  content: " >";
}
.home-banner__copy__cta a:last-child span span:nth-last-child(1n+2) {
  display: none;
}
@media screen and (min-width: 48em) {
  .home-banner__copy__cta a:last-child span span:nth-last-child(1n+2) {
    display: inline;
  }
}
@media screen and (min-width: 22.5em) {
  .home-banner__copy__cta a:last-child {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 35em) {
  .home-banner__copy__cta a:last-child {
    padding-left: 27px;
    padding-right: 27px;
  }
}
@media screen and (min-width: 62em) {
  .home-banner__copy__cta a:last-child {
    margin-left: 18px;
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 75em) {
  .home-banner__copy__cta a:last-child {
    display: inline-block;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    background: transparent;
    text-decoration: none;
    border-radius: 0;
    border: none;
    outline: none;
    opacity: 1;
    position: relative;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
    padding: 0;
  }
  .home-banner__copy__cta a:last-child svg path {
    fill: #ffffff;
  }
  .home-banner__copy__cta a:last-child:hover, .home-banner__copy__cta a:last-child:focus, .home-banner__copy__cta a:last-child:active {
    color: #BCAD98;
  }
  .home-banner__copy__cta a:last-child:hover svg path, .home-banner__copy__cta a:last-child:focus svg path, .home-banner__copy__cta a:last-child:active svg path {
    fill: #BCAD98;
  }
  .home-banner__copy__cta a:last-child:after {
    content: " >";
  }
}

.inner-banner {
  display: block;
  background-color: #ffffff;
  max-width: calc(1506px + 90px);
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 75em) {
  .inner-banner {
    padding: 45px 45px 0;
  }
}
.inner-banner__img {
  display: block;
  position: relative;
  width: 100%;
}
.inner-banner__img:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.inner-banner__content {
  position: absolute;
  color: #ffffff;
  z-index: 1;
  left: 6vw;
  right: 6vw;
  bottom: 6vw;
}
@media screen and (min-width: 48em) {
  .inner-banner__content {
    left: 4vw;
    right: 4vw;
    bottom: 4vw;
  }
}
@media screen and (min-width: 100em) {
  .inner-banner__content {
    left: 0;
    right: 45px;
    bottom: 45px;
  }
}
.inner-banner__content .section-header__title {
  color: #ffffff;
  line-height: 1.05;
}
@media screen and (min-width: 35em) {
  .inner-banner__content .section-header__title {
    font-size: 3.6rem;
  }
}
@media screen and (min-width: 48em) {
  .inner-banner__content .section-header__title {
    font-size: 6rem;
    line-height: 1;
  }
}
@media screen and (min-width: 62em) {
  .inner-banner__content .section-header__title {
    font-size: 6rem;
  }
}
@media screen and (min-width: 75em) {
  .inner-banner__content .section-header__title {
    font-size: 9rem;
  }
}
.inner-banner__content .section-header__title.long-title {
  line-height: 1;
}
@media screen and (min-width: 75em) {
  .inner-banner__content .section-header__title.long-title {
    font-size: 6rem;
  }
}
@media screen and (min-width: 87.5em) {
  .inner-banner__content .section-header__title.long-title {
    line-height: inherit;
    font-size: 9rem;
  }
}
.inner-banner__content .breadcrumb {
  color: rgba(255, 255, 255, 0.75);
}
.inner-banner__content .breadcrumb a {
  color: #ffffff;
}
.inner-banner__content .breadcrumb a:before {
  background-color: #1D1E26;
}
.inner-banner__content .breadcrumb a:hover, .inner-banner__content .breadcrumb a:focus {
  color: #ffffff;
}
@media screen and (min-width: 62em) {
  .inner-banner.inner-banner--sidebar .inner-banner__content {
    left: calc(((100vw - 8vw) * 0.33333333) + (2.555555vw * 2));
  }
}
@media screen and (min-width: 100em) {
  .inner-banner.inner-banner--sidebar .inner-banner__content {
    left: calc((1506px * 0.33333333) + 80px);
  }
}
.inner-banner.inner-banner--sidebar .breadcrumb {
  justify-content: flex-start;
}
.inner-banner.inner-banner--sidebar .section-header {
  text-align: left;
}

.image-gallery {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
}
@supports (display: grid) {
  .image-gallery {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
  @media screen and (min-width: 22.5em) {
    .image-gallery {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  }
  @media screen and (min-width: 35em) {
    .image-gallery {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
  .image-gallery.-clearfix:before, .image-gallery.-clearfix:after {
    content: none;
  }
}

.image-gallery-thumb {
  width: 100%;
  display: block;
  background-color: #F0F4F6;
}
@supports (aspect-ratio: 4/3) {
  .image-gallery-thumb {
    aspect-ratio: 4/3;
  }
}

.image-gallery-caption {
  margin-top: 4px;
}

.image-gallery-popup {
  display: block;
}

.image-gallery-thumb img {
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.image-gallery--captions {
  display: flex;
  flex-flow: row wrap;
}

.js--popup-gallery {
  position: relative;
}
.js--popup-gallery:before, .js--popup-gallery:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: transparent;
}
.js--popup-gallery:before {
  background: rgba(0, 0, 0, 0.5);
}
.js--popup-gallery:after {
  background-image: url(../assets/icon_magnify.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.media-object__img {
  float: left;
  width: 100%;
  height: 100%;
}

.media-object__img img {
  width: 100%;
}

.media-object__content {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.media-object {
  padding: 20px;
  width: 100%;
  float: left;
}

.media-object:after,
.media-object:before {
  clear: both;
}

.media-object__details {
  font-weight: bold;
  color: #7f7f7f;
}

.media-object__date:after {
  content: " | ";
}

@media screen and (min-width: 48em) {
  .media-object {
    padding: 0;
  }

  .media-object__img {
    width: 33%;
  }

  .media-object__content {
    width: 66%;
    margin-top: 0;
    padding-left: 20px;
  }
}
.card {
  background: rgba(233, 239, 242, 0.666);
  color: rgba(29, 30, 38, 0.8);
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: color 200ms ease, box-shadow 200ms ease;
}
.card:hover {
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
  color: #1D1E26;
}
.card:hover .card__picture {
  opacity: 0.75;
}
@media screen and (min-width: 75em) {
  .card:hover .card__cta {
    display: block;
    animation: fadeInUp 400ms ease-in-out;
    animation-fill-mode: both;
  }
}
.card:focus {
  outline: 1px dashed #1B244A;
  outline-offset: 3px;
}
.card:focus .card__picture {
  opacity: 0.66;
}
.card:focus-within {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}
.card__item {
  width: 100%;
  overflow: hidden;
  display: block;
}
.card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}
.card__description {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
}
.card__heading {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 300;
  font-size: 2.7rem;
  line-height: 1.5;
  color: #1D1E26;
}
@media screen and (min-width: 35em) {
  .card__heading {
    font-size: 3.6rem;
  }
}
@media screen and (min-width: 75em) {
  .card__heading {
    font-size: 7.2rem;
  }
}
.card__heading span:nth-child(1n+2) {
  display: none;
}
@media screen and (min-width: 35em) {
  .card__heading span:nth-child(1n+2) {
    display: inline;
  }
}
.card__copy {
  display: none;
  transition: text-shadow 200ms ease-in-out;
}
@media screen and (min-width: 48em) {
  .card__copy {
    display: block;
    padding-left: 27px;
    padding-right: 27px;
    font-size: 1.3125rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: -1px 1px 0 rgba(255, 255, 255, 0), 0 0 12px rgba(255, 255, 255, 0);
  }
}
@media screen and (min-width: 62em) {
  .card__copy {
    overflow: auto;
    text-overflow: initial;
    -webkit-line-clamp: unset;
  }
}
.card__cta {
  margin-top: 5px;
  line-height: 1.5;
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
}
.card__cta svg path {
  fill: #1B244A;
}
.card__cta:hover, .card__cta:focus, .card__cta:active {
  color: #1D1E26;
}
.card__cta:hover svg path, .card__cta:focus svg path, .card__cta:active svg path {
  fill: #1D1E26;
}
.card__cta:after {
  content: " >";
}
@media screen and (min-width: 75em) {
  .card__cta {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }
}
.card__picture {
  opacity: 0.5;
  transition: opacity 200ms ease;
}
@supports (aspect-ratio: 9/16) {
  .card__picture {
    aspect-ratio: 9/16;
  }
  @media screen and (min-width: 62em) {
    .card__picture {
      aspect-ratio: 103/120;
    }
  }
}
@media screen and (min-width: 48em) {
  .card__picture {
    width: 50%;
  }
}
@supports (aspect-ratio: 9/16) {
  .card__image {
    aspect-ratio: 9/16;
    object-fit: cover;
  }
  @media screen and (min-width: 62em) {
    .card__image {
      aspect-ratio: 103/120;
    }
  }
}

.show-title .card__heading {
  line-height: 1.2;
  margin-bottom: 0.3em;
}
@media screen and (min-width: 35em) {
  .show-title .card__heading {
    line-height: 1.5;
  }
}
.show-title .card__heading span:nth-child(1n+2) {
  display: block;
}
@media screen and (min-width: 35em) {
  .show-title .card__heading span:nth-child(1n+2) {
    display: inline;
  }
}

@media screen and (min-width: 48em) {
  .js .js-animate-section .card__item {
    opacity: 0;
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .card__item:nth-child(n+3) {
    transition-delay: 500ms;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .card__item:nth-child(odd) {
    transform: translate(-30px, 30px);
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .card__item:nth-child(even) {
    transform: translate(30px, 30px);
  }
}
.js .js-animate-section.animate-section .card__item {
  opacity: 1;
  transform: translate(0, 0);
}

.tile {
  background: #ffffff;
}
.tile__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tile__image {
  position: relative;
  margin-bottom: 21px;
}
.tile__image img {
  width: 100%;
}
.tile__subtitle {
  display: inline-block;
  position: absolute;
  top: 21px;
  left: -15px;
}
@media screen and (min-width: 62em) {
  .tile__subtitle {
    left: -21px;
  }
}
.tile__heading {
  margin-bottom: 1.5rem;
  color: #1D1E26;
  line-height: normal;
}
@media screen and (min-width: 62em) {
  .tile__heading {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 75em) {
  .tile__heading {
    font-size: 2.1rem;
  }
}
.tile__description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 48em) {
  .tile__description {
    -webkit-line-clamp: 5;
  }
}
@media screen and (min-width: 62em) {
  .tile__description {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 75em) {
  .tile__description {
    overflow: auto;
    text-overflow: initial;
    -webkit-line-clamp: unset;
  }
}
.tile__cta {
  margin-top: auto;
}
.tile__cta a {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
}
.tile__cta a svg path {
  fill: #1B244A;
}
.tile__cta a:hover, .tile__cta a:focus, .tile__cta a:active {
  color: #1D1E26;
}
.tile__cta a:hover svg path, .tile__cta a:focus svg path, .tile__cta a:active svg path {
  fill: #1D1E26;
}
.tile__cta a:after {
  content: " >";
}

.tile:nth-child(3n) .tile__subtitle {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #1B244A;
}
@media screen and (min-width: 62em) {
  .tile:nth-child(3n) .tile__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}

.tile:nth-child(3n+1) .tile__subtitle {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #571E25;
}
@media screen and (min-width: 62em) {
  .tile:nth-child(3n+1) .tile__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}

.tile:nth-child(3n+2) .tile__subtitle {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #734226;
}
@media screen and (min-width: 62em) {
  .tile:nth-child(3n+2) .tile__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}

@media screen and (min-width: 48em) and (orientation: landscape) {
  .js .js-animate-section .tile {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }
}
@media screen and (min-width: 48em) and (orientation: landscape) {
  .js .js-animate-section .tile__subtitle {
    opacity: 0;
    transform: translateX(-30px);
    transition: opacity 350ms 250ms ease-in-out, transform 350ms 250ms ease-in-out;
  }
}
@media screen and (min-width: 48em) and (orientation: landscape) {
  .js .js-animate-section .tile__cta {
    opacity: 0;
    transform: translateX(-30px);
    transition: opacity 350ms 500ms ease-in-out, transform 350ms 500ms ease-in-out;
  }
}
.js .js-animate-section.animate-section .tile {
  opacity: 1;
  transform: translateY(0px);
}
.js .js-animate-section.animate-section .tile__subtitle {
  opacity: 1;
  transform: translateX(0px);
}
.js .js-animate-section.animate-section .tile__cta {
  opacity: 1;
  transform: translateX(0px);
}

@media screen and (min-width: 48em) {
  .icon-card__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-right: -9px;
  }
}
@media screen and (min-width: 62em) {
  .icon-card__list {
    margin-left: -18px;
    margin-right: -18px;
  }
}
@media screen and (min-width: 75em) {
  .icon-card__list {
    margin-left: -27px;
    margin-right: -27px;
  }
}

.icon-card__item {
  margin-bottom: 45px;
  margin-left: -6vw;
}
@media screen and (min-width: 35em) {
  .icon-card__item {
    margin-left: 0;
  }
}
@media screen and (min-width: 48em) {
  .icon-card__item {
    flex: 0 0 33.333%;
    padding-left: 9px;
    padding-right: 9px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 62em) {
  .icon-card__item {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media screen and (min-width: 75em) {
  .icon-card__item {
    padding-left: 27px;
    padding-right: 27px;
  }
}

.icon-card {
  display: block;
  background-color: #ffffff;
  border-left: 6vw solid #1B244A;
  padding-left: 36px;
  padding-top: 9px;
  padding-bottom: 9px;
}
@media screen and (min-width: 35em) {
  .icon-card {
    text-align: center;
    box-shadow: 0 2px 36px rgba(0, 0, 0, 0.15), 0 2px 18px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border-left: none;
    padding: 45px;
  }
}
@media screen and (min-width: 48em) {
  .icon-card {
    padding: 27px;
    padding-top: 40px;
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 75em) {
  .icon-card {
    padding: 45px;
  }
}
.icon-card__icon {
  border: 2.75px solid #9c9c9c;
  width: 144px;
  height: 144px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 35em) {
  .icon-card__icon {
    margin: 0 auto;
  }
}
@media screen and (min-width: 48em) {
  .icon-card__icon {
    width: 81px;
    height: 81px;
  }
}
@media screen and (min-width: 62em) {
  .icon-card__icon {
    width: 144px;
    height: 144px;
  }
}
.icon-card__icon svg {
  display: block;
  margin: 0 auto;
  width: 81px;
  height: 81px;
}
@media screen and (min-width: 48em) {
  .icon-card__icon svg {
    width: 45px;
    height: 45px;
  }
}
@media screen and (min-width: 62em) {
  .icon-card__icon svg {
    width: 81px;
    height: 81px;
  }
}
.icon-card__icon svg path,
.icon-card__icon svg rect,
.icon-card__icon svg line,
.icon-card__icon svg circle {
  stroke: #9c9c9c;
  stroke-width: 0.66;
}
@media screen and (min-width: 48em) {
  .icon-card__icon svg path,
.icon-card__icon svg rect,
.icon-card__icon svg line,
.icon-card__icon svg circle {
    stroke-width: 1;
  }
}
@media screen and (min-width: 62em) {
  .icon-card__icon svg path,
.icon-card__icon svg rect,
.icon-card__icon svg line,
.icon-card__icon svg circle {
    stroke-width: 0.66;
  }
}
.icon-card__title {
  color: #1B244A;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 48em) {
  .icon-card__title {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }
}
@media screen and (min-width: 62em) {
  .icon-card__title {
    font-size: 2.1rem;
    margin-bottom: 1.5rem;
  }
}
.icon-card__excerpt {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .icon-card__excerpt {
    font-size: 1.3125rem;
  }
}
@media screen and (min-width: 62em) {
  .icon-card__excerpt {
    font-size: 1.5rem;
  }
}

.slider {
  position: relative;
}
.slider__track {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}

.slider__dots {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: none;
}
@media screen and (min-width: 25em) {
  .slider__dots {
    display: flex;
  }
}
.slider__dots button {
  background: none;
  border: none;
  outline: none;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.slider__dots a,
.slider__dots button {
  padding: 12px;
  position: relative;
}
.slider__dots a span,
.slider__dots button span {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.slider__dots a span.focusable:active, .slider__dots a span.focusable:focus,
.slider__dots button span.focusable:active,
.slider__dots button span.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.slider__dots a:before,
.slider__dots button:before {
  content: "";
  background-color: transparent;
  border: 2px solid #9c9c9c;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 200ms ease, box-shadow 200ms ease;
}
.slider__dots a:hover:before,
.slider__dots button:hover:before {
  background-color: #6A6D73;
  border-color: #6A6D73;
}
.slider__dots a:focus:before,
.slider__dots button:focus:before {
  background-color: #6A6D73;
  border-color: #6A6D73;
}
.slider__dots a.active:before,
.slider__dots button.active:before {
  background-color: #6A6D73;
  border-color: #6A6D73;
  transition-delay: 50ms;
}

.slider__arrows {
  position: absolute;
  top: calc(50% - 18px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  padding: 0;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  width: 36px;
  height: 36px;
}
@media screen and (min-width: 30em) {
  .slider__arrows {
    width: 54px;
    height: 54px;
    top: calc(50% - 27px);
  }
}
.slider__arrows.previous {
  right: 100%;
}
.slider__arrows.previous:hover svg {
  transform: scale(1.33) translateX(-3px);
}
.slider__arrows.next {
  left: 100%;
}
.slider__arrows.next:hover svg {
  transform: scale(1.33) translateX(3px);
}
.slider__arrows:focus svg path {
  fill: #000000;
}
.slider__arrows * {
  pointer-events: none;
}
.slider__arrows svg {
  transform: scale(1) translateX(0);
  transition: transform 250ms ease-in-out;
}
@media screen and (min-width: 30em) {
  .slider__arrows svg {
    width: 10px;
    height: 12px;
  }
}

.slider-clean {
  position: relative;
}

[aria-label=gallery]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px;
  /* compensates for 2px border */
}

.slider__item img {
  object-fit: cover;
}

.gallery {
  transition: padding-top 300ms ease-in-out;
}
@media screen and (min-width: 75em) {
  .gallery {
    display: flex;
  }
}
.gallery__slider {
  position: relative;
  padding-bottom: 54px;
}
.gallery__slider__track {
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.gallery__slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.gallery .slider__dots {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 24px;
  z-index: 1;
  display: none;
}
@media screen and (min-width: 25em) {
  .gallery .slider__dots {
    display: flex;
  }
}
.gallery .slider__dots a {
  padding: 12px;
  position: relative;
}
.gallery .slider__dots a span {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.gallery .slider__dots a span.focusable:active, .gallery .slider__dots a span.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.gallery .slider__dots a:before {
  content: "";
  background-color: transparent;
  border: 2px solid #9c9c9c;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 200ms ease, box-shadow 200ms ease;
}
.gallery .slider__dots a:hover:before {
  background-color: #6A6D73;
  border-color: #6A6D73;
}
.gallery .slider__dots a:focus:before {
  background-color: #6A6D73;
  border-color: #6A6D73;
}
.gallery .slider__dots a.active:before {
  background-color: #6A6D73;
  border-color: #6A6D73;
  transition-delay: 50ms;
}
.gallery .slider__arrows {
  position: absolute;
  top: calc(50% - 18px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  padding: 0;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  width: 36px;
  height: 36px;
}
@media screen and (min-width: 30em) {
  .gallery .slider__arrows {
    width: 54px;
    height: 54px;
    top: calc(50% - 27px);
  }
}
.gallery .slider__arrows.previous {
  right: 100%;
}
.gallery .slider__arrows.previous:hover svg {
  transform: scale(1.33) translateX(-3px);
}
.gallery .slider__arrows.next {
  left: 100%;
}
.gallery .slider__arrows.next:hover svg {
  transform: scale(1.33) translateX(3px);
}
.gallery .slider__arrows:focus svg path {
  fill: #000000;
}
.gallery .slider__arrows * {
  pointer-events: none;
}
.gallery .slider__arrows svg {
  transform: scale(1) translateX(0);
  transition: transform 250ms ease-in-out;
}
@media screen and (min-width: 30em) {
  .gallery .slider__arrows svg {
    width: 10px;
    height: 12px;
  }
}
.gallery__slide {
  display: block;
  width: 50%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: 0 9px 0 0;
}
@media screen and (min-width: 35em) {
  .gallery__slide {
    padding: 0 27px 0 0;
  }
}
@media screen and (min-width: 48em) {
  .gallery__slide {
    width: 25%;
    padding: 0 45px 0 0;
  }
}
@media screen and (min-width: 62em) {
  .gallery__slide {
    width: 20%;
  }
}
@media screen and (min-width: 75em) {
  .gallery__slide {
    width: 16.66666666%;
  }
}
.gallery__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery__slide:after, .gallery__slide:before {
  right: 9px;
}
@media screen and (min-width: 35em) {
  .gallery__slide:after, .gallery__slide:before {
    right: 27px;
  }
}
@media screen and (min-width: 48em) {
  .gallery__slide:after, .gallery__slide:before {
    right: 45px;
  }
}
.gallery__slide__picture {
  display: block;
  height: 100%;
}
.gallery__slide:first-child {
  padding-left: 9px;
}
@media screen and (min-width: 35em) {
  .gallery__slide:first-child {
    padding-left: 27px;
  }
}
@media screen and (min-width: 48em) {
  .gallery__slide:first-child {
    padding-left: 45px;
  }
}

.cta-box {
  margin-bottom: 32px;
}
.cta-box__heading {
  margin-top: 0;
}
.cta-box__subtitle {
  position: absolute;
  left: -15px;
  top: 0;
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #571E25;
  font-size: 1.2rem;
  padding: 6px 9px;
}
@media screen and (min-width: 62em) {
  .cta-box__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
    font-size: 1.2rem;
    padding: 6px 9px;
  }
}
.cta-box__title {
  display: block;
  position: relative;
  padding-top: 36px;
  font-size: 2.25rem;
  margin-bottom: 1.2rem;
  color: #2b2b2b;
}
@media screen and (min-width: 87.5em) {
  .cta-box__title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }
}
.cta-box__cta a {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
}
.cta-box__cta a svg path {
  fill: #1B244A;
}
.cta-box__cta a:hover, .cta-box__cta a:focus, .cta-box__cta a:active {
  color: #1D1E26;
}
.cta-box__cta a:hover svg path, .cta-box__cta a:focus svg path, .cta-box__cta a:active svg path {
  fill: #1D1E26;
}
.cta-box__cta a:after {
  content: " >";
}
.cta-box__content > *:last-child {
  margin-bottom: 0;
}

/* ---- Slide Toggle ---- */
.slide-toggle__trigger {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  position: relative;
  white-space: normal;
  vertical-align: middle;
  outline: none;
  border: none;
  transition: all 250ms ease-in-out;
  background-color: #F0F4F6;
  background-color: transparent;
  padding: 8px 0;
  margin: 0;
  text-align: left;
  color: #7f7f7f;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
}
.slide-toggle__trigger:hover, .slide-toggle__trigger:focus {
  color: #2b2b2b;
}
.slide-toggle__trigger:hover:before, .slide-toggle__trigger:hover:after, .slide-toggle__trigger:focus:before, .slide-toggle__trigger:focus:after {
  background-color: #2b2b2b;
}
.slide-toggle__trigger:before, .slide-toggle__trigger:after {
  content: " ";
  width: 12px;
  height: 3px;
  display: block;
  position: absolute;
  background-color: #7f7f7f;
  margin: 0 auto;
  right: 20px;
  top: 50%;
  transition: all 400ms ease;
}
.slide-toggle__trigger:before {
  transform: rotate(45deg) translateX(-5px);
}
.slide-toggle__trigger:after {
  transform: rotate(-45deg) translateX(5px);
}
.slide-toggle__trigger[aria-pressed=true] {
  color: #1B244A;
}
.slide-toggle__trigger[aria-pressed=true]:before, .slide-toggle__trigger[aria-pressed=true]:after {
  background-color: #1B244A;
  top: calc(50% - 6px);
}
.slide-toggle__trigger[aria-pressed=true]:hover, .slide-toggle__trigger[aria-pressed=true]:focus {
  color: #2b2b2b;
}
.slide-toggle__trigger[aria-pressed=true]:hover:before, .slide-toggle__trigger[aria-pressed=true]:hover:after, .slide-toggle__trigger[aria-pressed=true]:focus:before, .slide-toggle__trigger[aria-pressed=true]:focus:after {
  background-color: #2b2b2b;
}
.slide-toggle__trigger[aria-pressed=true]:before {
  transform: rotate(-45deg) translateX(-5px);
}
.slide-toggle__trigger[aria-pressed=true]:after {
  transform: rotate(45deg) translateX(5px);
}

.archive-links__list-depth0 .slide-toggle__trigger {
  font-weight: 400;
}

.archive-links__list[aria-expanded=false] {
  display: none;
}

.archive-links__list-depth0 .slide-toggle__trigger {
  margin-top: 0.75rem;
}
.archive-links__list-depth0 .slide-toggle__trigger:first-child {
  margin-top: 0;
}

/* Tabs layout */
.tabs-list {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 12px;
  list-style-type: none;
}
@media screen and (min-width: 30em) {
  .tabs-list {
    /* 480px */
    margin-bottom: 8px;
  }
}

.tabs-list__item {
  display: inline-block;
  margin-right: 16px;
}
@media screen and (min-width: 30em) {
  .tabs-list__item {
    /* 480px */
    margin-right: 24px;
  }
}

.tabs-list__button {
  position: relative;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  padding: 0;
  line-height: 1.2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  text-decoration: none;
  color: #6A6D73;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 2.1rem;
  font-weight: 400;
}
@media screen and (min-width: 48em) {
  .tabs-list__button {
    font-size: 3rem;
  }
}
.tabs-list__button span {
  pointer-events: none;
}

.tab-btn__chevron-icon,
.tab-btn__title {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
}

.tab-btn__chevron-icon {
  position: relative;
  margin-top: 0;
  height: 12px;
  width: 20px;
  margin-left: 4px;
}
@media screen and (min-width: 48em) {
  .tab-btn__chevron-icon {
    margin-left: 8px;
  }
}

.tab-btn__chevron-icon:before,
.tab-btn__chevron-icon:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 12px;
  height: 2px;
  background-color: #6A6D73;
}

.tab-btn__chevron-icon:before {
  transform: rotate(45deg);
  left: 0;
}

.tab-btn__chevron-icon:after {
  transform: rotate(-45deg);
  right: 0;
}

.tab-btn__title {
  margin-top: 0;
}

/* -- Hover styles -- */
.tabs-list__button:hover .tab-btn__title,
.tabs-list__button:focus .tab-btn__title {
  color: #000000;
}
.tabs-list__button:hover .tab-btn__chevron-icon:before,
.tabs-list__button:hover .tab-btn__chevron-icon:after,
.tabs-list__button:focus .tab-btn__chevron-icon:before,
.tabs-list__button:focus .tab-btn__chevron-icon:after {
  background-color: #000000;
}

/* -- Active tab styles -- */
.tabs-list__item--active .tabs-list__button {
  color: #1D1E26;
}
.tabs-list__item--active .tab-btn__chevron-icon:before,
.tabs-list__item--active .tab-btn__chevron-icon:after {
  background-color: #1D1E26;
}
.tabs-list__item--active .tabs-list__button:hover .tab-btn__title,
.tabs-list__item--active .tabs-list__button:focus .tab-btn__title {
  color: #29366f;
}
.tabs-list__item--active .tabs-list__button:hover .tab-btn__chevron-icon:before,
.tabs-list__item--active .tabs-list__button:hover .tab-btn__chevron-icon:after,
.tabs-list__item--active .tabs-list__button:focus .tab-btn__chevron-icon:before,
.tabs-list__item--active .tabs-list__button:focus .tab-btn__chevron-icon:after {
  background-color: #29366f;
}

/* ---- The tab content ---- */
.tab {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tab.tab--active {
  clip: auto;
  height: auto;
  margin: auto;
  overflow: visible;
  position: relative;
  width: auto;
  z-index: 1;
}

@media only screen and (min-width: 62em) {
  /* 992px */
  .tab.tab--active {
    -webkit-animation: fadeInUp 200ms ease;
    animation: fadeInUp 200ms ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}
.fields__tabs--1 .tab {
  clip: auto;
  height: auto;
  margin: auto;
  overflow: visible;
  position: relative;
  width: auto;
  z-index: 1;
}

.testimonials__slider {
  position: relative;
  margin: 0 auto;
  width: 88vw;
}
@media screen and (min-width: 30em) {
  .testimonials__slider {
    width: 92vw;
  }
}
@media screen and (min-width: 48em) {
  .testimonials__slider {
    width: calc(100% - 112px);
  }
}
@media screen and (min-width: 75em) {
  .testimonials__slider {
    width: calc(100% - 8vw);
  }
}
.testimonials__slider .splide__track {
  overflow: visible;
}
@media screen and (min-width: 48em) {
  .testimonials__slider .splide__track {
    overflow: hidden;
  }
}

.splide__pagination {
  display: none;
  justify-content: flex-start;
  padding: 0;
}
@media screen and (min-width: 48em) {
  .splide__pagination {
    justify-content: center;
    margin-bottom: 40px;
  }
}
.splide__pagination button {
  padding: 12px;
  position: relative;
  background: none;
  border: none;
  outline: none;
}
.splide__pagination button span {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.splide__pagination button span.focusable:active, .splide__pagination button span.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.splide__pagination button:before {
  content: "";
  background-color: rgba(156, 156, 156, 0.5);
  height: 8px;
  width: 8px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.splide__pagination button:hover:before {
  background-color: #9c9c9c;
}
.splide__pagination button:focus:before {
  background-color: #9c9c9c;
}
.splide__pagination button.is-active:before {
  background-color: #BCAD98;
  transition-delay: 50ms;
}

@media screen and (min-width: 35em) {
  .splide__list {
    align-items: center;
  }
}
@media screen and (min-width: 48em) {
  .splide__list {
    align-items: stretch;
  }
}

.testimonials__arrow {
  position: absolute;
  top: calc(50% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  padding: 0;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  width: 6vw;
  height: 40px;
  z-index: 3;
}
@media screen and (min-width: 30em) {
  .testimonials__arrow {
    width: 56px;
    height: 56px;
  }
}
.testimonials__arrow.splide__arrow--prev {
  right: 100%;
}
@media screen and (min-width: 30em) {
  .testimonials__arrow.splide__arrow--prev {
    justify-content: flex-end;
  }
}
.testimonials__arrow.splide__arrow--next {
  left: 100%;
}
@media screen and (min-width: 30em) {
  .testimonials__arrow.splide__arrow--next {
    justify-content: flex-start;
  }
}
.testimonials__arrow * {
  pointer-events: none;
}
@media screen and (min-width: 30em) {
  .testimonials__arrow svg {
    width: 10px;
    height: 13px;
  }
}

.testimonial__item {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
@media screen and (min-width: 35em) {
  .testimonial__item {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 18px;
  }
}
@media screen and (min-width: 48em) {
  .testimonial__item {
    padding: 36px;
  }
}
@media screen and (min-width: 62em) {
  .testimonial__item {
    flex: 0 0 33.333%;
    padding: 36px 0;
  }
}
.testimonial__box {
  display: block;
  background-color: #ffffff;
  position: relative;
  padding: 27px 0;
}
@media screen and (min-width: 35em) {
  .testimonial__box {
    position: static;
    padding: 27px;
    box-shadow: 0 2px 36px rgba(0, 0, 0, 0.15), 0 2px 18px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
  }
}
@media screen and (min-width: 48em) {
  .testimonial__box {
    padding: 45px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.testimonial__box:before, .testimonial__box:after {
  content: "";
  position: absolute;
  width: 45px;
  left: 0;
  height: 3px;
  background-color: #9c9c9c;
  border-radius: 3px;
}
@media screen and (min-width: 35em) {
  .testimonial__box:before, .testimonial__box:after {
    content: none;
  }
}
.testimonial__box:before {
  top: 0;
}
.testimonial__box:after {
  bottom: 0;
}
.testimonial__quote {
  font-size: 1.5rem;
  color: #6A6D73;
  transition: opacity 400ms 25ms ease-in-out, transform 400ms 25ms ease-in-out;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: calc(6 * 1.5em);
}
.testimonial__attribution {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.5rem;
  color: #571E25;
  margin-top: 0;
  transition: opacity 400ms 200ms ease, transform 400ms 200ms ease;
}
@media screen and (min-width: 35em) {
  .testimonial__attribution {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 62em) {
  .testimonial__attribution {
    font-size: 2.1rem;
  }
}
.testimonial__job {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 0.9em;
  color: #6A6D73;
  margin-bottom: 0;
}
.testimonial__cta {
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.testimonial__cta a {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
}
.testimonial__cta a svg path {
  fill: #1B244A;
}
.testimonial__cta a:hover, .testimonial__cta a:focus, .testimonial__cta a:active {
  color: #1D1E26;
}
.testimonial__cta a:hover svg path, .testimonial__cta a:focus svg path, .testimonial__cta a:active svg path {
  fill: #1D1E26;
}
.testimonial__cta a:after {
  content: " >";
}

.js .testimonial__box {
  transition: opacity 200ms ease, transform 300ms ease;
  position: relative;
  transform: scale(0.8);
}
@media screen and (min-width: 48em) {
  .js .testimonial__item.is-active .testimonial__box {
    transform: scale(1);
    z-index: 1;
  }
}

.google-rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.google-rating svg {
  margin-right: 12px;
}
.google-rating svg:last-child {
  margin-right: 0;
}
.google-rating__subtitle {
  flex: 0 0 100%;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
}
@media screen and (min-width: 62em) {
  .google-rating__subtitle {
    font-size: 1.5rem;
  }
}

.testimonial-section-header {
  margin-bottom: 27px;
}

@media screen and (min-width: 48em) {
  .js .js-animate-section .testimonial-section-header,
.js .js-animate-section .google-rating,
.js .js-animate-section .testimonials__slider {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .google-rating {
    transition-delay: 200ms;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .testimonials__slider {
    transition-delay: 400ms;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .testimonial-section-header,
.js .js-animate-section.animate-section .google-rating,
.js .js-animate-section.animate-section .testimonials__slider {
    opacity: 1;
    transform: translateY(0);
  }
}

.testimonial-gallery {
  z-index: 1;
}
.testimonial-gallery:after {
  z-index: -1;
}
.testimonial-gallery__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (min-width: 75em) {
  .testimonial-gallery__row {
    margin-left: -22.5px;
    margin-right: -22.5px;
  }
}
.testimonial-gallery__video {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 45px;
}
@media screen and (min-width: 75em) {
  .testimonial-gallery__video {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 22.5px;
    padding-right: 22.5px;
    margin-bottom: 0;
  }
}
.testimonial-gallery__video video {
  box-shadow: 0 2px 36px rgba(0, 0, 0, 0.15), 0 2px 18px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
@media screen and (min-width: 75em) {
  .testimonial-gallery__video video {
    object-fit: cover;
  }
}
.testimonial-gallery__slider {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}
@media screen and (min-width: 75em) {
  .testimonial-gallery__slider {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 22.5px;
    padding-right: 22.5px;
  }
}
.testimonial-gallery__slider__wrapper {
  background-color: #ffffff;
  box-shadow: 0 2px 36px rgba(0, 0, 0, 0.15), 0 2px 18px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 27px;
  text-align: center;
  height: 100%;
}
@media screen and (min-width: 35em) {
  .testimonial-gallery__slider__wrapper {
    padding: 45px;
  }
}
.testimonial-gallery__slider__title {
  font-size: 3rem;
}
.testimonial-gallery__slider .testimonials__slider {
  position: static;
  width: 100%;
}
@media screen and (min-width: 30em) {
  .testimonial-gallery__slider .testimonials__slider {
    width: 100%;
  }
}
.testimonial-gallery__slider .testimonials__slider .splide__track {
  overflow: hidden;
}
.testimonial-gallery .testimonials__arrow.splide__arrow--prev {
  left: 0;
  right: auto;
}
@media screen and (min-width: 30em) {
  .testimonial-gallery .testimonials__arrow.splide__arrow--prev {
    justify-content: center;
  }
}
@media screen and (min-width: 48em) {
  .testimonial-gallery .testimonials__arrow.splide__arrow--prev {
    justify-content: flex-end;
  }
}
.testimonial-gallery .testimonials__arrow.splide__arrow--next {
  left: auto;
  right: 0;
}
@media screen and (min-width: 30em) {
  .testimonial-gallery .testimonials__arrow.splide__arrow--next {
    justify-content: center;
  }
}
@media screen and (min-width: 48em) {
  .testimonial-gallery .testimonials__arrow.splide__arrow--next {
    justify-content: flex-start;
  }
}
.testimonial-gallery .testimonial__item {
  scroll-snap-align: center;
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 48em) {
  .testimonial-gallery .testimonial__item {
    flex: 0 0 100%;
  }
}
.testimonial-gallery .testimonial__box {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 9px;
}
@media screen and (min-width: 48em) {
  .testimonial-gallery .testimonial__box {
    padding: 0 36px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 75em) {
  .testimonial-gallery .testimonial__box {
    padding: 0 24px;
  }
}
@media screen and (min-width: 87.5em) {
  .testimonial-gallery .testimonial__box {
    padding: 0 36px;
  }
}
.testimonial-gallery .testimonial__box:before, .testimonial-gallery .testimonial__box:after {
  content: none;
}
.testimonial-gallery .testimonial__quote {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: calc(6 * 1.5em);
}
@media screen and (min-width: 48em) {
  .testimonial-gallery .testimonial__quote {
    -webkit-line-clamp: 5;
    max-height: calc(5 * 1.5em);
  }
}

.js .testimonial-gallery .testimonial__box {
  opacity: 1;
  transform: scale(1);
}
.js .testimonial-gallery .testimonial__item .testimonial__box {
  margin-right: 0;
  margin-left: 0;
}
.js .testimonial-gallery .testimonial__item.active .testimonial__box {
  margin-left: 0;
  margin-right: 0;
}
.js .testimonial-gallery .testimonial__item.active + .testimonial__item .testimonial__box {
  margin-left: 0;
  margin-right: 0;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  text-decoration: none;
  outline-offset: 2px;
  box-shadow: 0 0 0 transparent;
  transition: color 250ms ease-in-out, outline 250ms ease-in-out, background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}
.badge[href]:hover, .badge[href]:focus {
  outline: thin dotted #ffffff;
  box-shadow: 0 2px 8px rgba(13, 8, 0, 0.33);
}
.badge[href]:focus {
  outline: thin solid #ffffff;
}

.badge__title {
  font-weight: 700;
  color: #2b2b2b;
}
.badge__title a {
  color: inherit;
}

.badge-grey {
  background-color: #F0F4F6;
  color: #4A4B51;
}
.badge-grey:hover, .badge-grey:focus {
  color: #ffffff;
  background-color: #4A4B51;
}

.badge-white {
  background-color: #ffffff;
  color: #1B244A;
}
.badge-white:hover, .badge-white:focus {
  color: #ffffff;
  background-color: #1B244A;
}

.mr-auto {
  margin-right: auto;
}

.title-value-item {
  margin-top: 9px;
  margin-bottom: 9px;
}
.title-value-item__title {
  font-size: 75%;
  display: block;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}
.title-value-item__title .field-helper {
  text-transform: none;
}
.title-value-item__value {
  display: block;
  line-height: normal;
  padding-left: 9px;
  font-weight: 400;
}

p.title-value-item__title {
  margin-bottom: 0.25em;
}

/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */
/* ---- Global ---- */
#header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 146px;
  background-color: #fff;
  z-index: 99;
  transition: height 250ms ease-in-out;
}
@media screen and (min-width: 125em) {
  #header {
    /* 2000px */
    max-width: 1920px;
    margin: 0 auto;
  }
}

.section--header {
  background: #ffffff;
  padding: 9px;
  box-shadow: 0 2px 32px rgba(0, 0, 0, 0.25);
  transition: box-shadow 200ms ease, padding 250ms ease-in-out;
}
@media screen and (min-width: 62em) {
  .section--header {
    padding: 18px;
  }
}
.section--header > .container {
  width: auto;
}
@media screen and (min-width: 62em) {
  .section--header > .container {
    width: 92%;
  }
}

.home .section--header {
  box-shadow: 0 0 32px rgba(0, 0, 0, 0);
}

.header__navigation {
  padding-right: 62px;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 62em) {
  .header__navigation {
    padding-right: 0;
    display: block;
  }
}
.header__navigation svg {
  opacity: 0.75;
}

.header__logo a {
  display: block;
}
.header__logo a img {
  height: 45px;
  width: auto;
  transition: height 250ms ease-in-out;
}
@media screen and (min-width: 62em) {
  .header__logo a img {
    height: 108px;
  }
}

.header__search .field-element {
  margin-bottom: 0;
}

.header__mobile-link {
  display: flex;
  align-items: center;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  color: #4A4B51;
  text-decoration: none;
  margin-right: 1.5rem;
}
@media screen and (min-width: 62em) {
  .header__mobile-link {
    font-size: 1.5rem;
  }
}
.header__mobile-link svg {
  margin-right: 7px;
}
@media screen and (min-width: 62em) {
  .header__mobile-link {
    display: none;
  }
}
.header__mobile-link__label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.header__mobile-link__label.focusable:active, .header__mobile-link__label.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
@media screen and (min-width: 22.5em) {
  .header__mobile-link__label {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

/** Mobile menu only
===================================*/
@media all and (max-width: 61.9375em) {
  #header {
    background-color: #ffffff;
    height: 63px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
  #header:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 63px;
    left: 0;
    right: 0;
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.2), 0 1px 15px rgba(0, 0, 0, 0.15);
    z-index: -1;
  }
}
@media screen and (min-width: 62em) {
  .header-shrink #header {
    height: 112px;
  }
}
@media screen and (min-width: 62em) {
  .header-shrink .section--header {
    padding-top: 9px;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 62em) {
  .header-shrink .topnav {
    margin-top: 6px;
  }
}
@media screen and (min-width: 62em) {
  .header-shrink .header__logo a img {
    height: 96px;
  }
}

.footer {
  background: #1D1E26;
  color: #ffffff;
  padding-bottom: 0;
}
.footer .footer__container {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .footer .footer__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, auto);
      column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 62em) {
    .footer .footer__container {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, auto);
      column-gap: 36px;
    }
  }
}
@media screen and (min-width: 62em) {
  .footer .footer__container {
    padding-bottom: 63px;
  }
}
@media screen and (min-width: 87.5em) {
  .footer .footer__container {
    padding-bottom: 81px;
  }
}
.footer__contact {
  order: -1;
  margin-bottom: 63px;
  flex: 1 1 100%;
  max-width: 100%;
}
@media screen and (min-width: 35em) {
  .footer__contact {
    order: -2;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 48em) {
  @supports (display: grid) {
    .footer__contact {
      flex-basis: auto;
      max-width: unset;
      grid-column: 1/2;
    }
  }
}
@media screen and (min-width: 62em) {
  .footer__contact {
    order: unset;
    margin-bottom: 0;
  }
  @supports (display: grid) {
    .footer__contact {
      grid-column: 4/5;
      grid-row: 1;
    }
  }
}
.footer__link-collection {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 36px;
}
@media screen and (min-width: 35em) {
  .footer__link-collection {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 48em) {
  .footer__link-collection {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  @supports (display: grid) {
    .footer__link-collection {
      flex-basis: auto;
      max-width: unset;
    }
  }
}
@media screen and (min-width: 62em) {
  .footer__link-collection {
    flex: 1 1 auto;
    max-width: 100%;
    margin-bottom: 0;
  }
}
.footer__link-collection__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  columns: 2;
}
@media screen and (min-width: 35em) {
  .footer__link-collection__list {
    columns: unset;
  }
}
@supports (display: grid) {
  .footer__link-collection__list {
    columns: unset;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }
  @media screen and (min-width: 35em) {
    .footer__link-collection__list {
      display: block;
    }
  }
}
.footer__link-collection__title {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  font-size: 1.5rem;
}
@media screen and (min-width: 62em) {
  .footer__link-collection__title {
    font-size: 1.5rem;
  }
}
.footer__link-collection a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  line-height: normal;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.footer__link-collection a:hover, .footer__link-collection a:focus {
  color: #ffffff;
}
.footer__link-collection a[title~=activities] {
  grid-column: 1/3;
}
@media screen and (min-width: 48em) {
  .footer .baby-wear-accessories {
    flex-basis: 66.666%;
    max-width: 66.666%;
    order: 1;
  }
  @supports (display: grid) {
    .footer .baby-wear-accessories {
      flex-basis: auto;
      max-width: unset;
    }
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    .footer .baby-wear-accessories {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }
}
.footer .baby-wear-accessories .footer__link-collection__list {
  columns: unset;
}
@supports (display: grid) {
  .footer .baby-wear-accessories .footer__link-collection__list {
    display: block;
  }
}
.footer .sporting-clubs {
  flex: 1 1 auto;
  max-width: 100%;
}
.footer .sporting-clubs .footer__link-collection__list {
  columns: 2;
}
@media screen and (min-width: 48em) {
  .footer .sporting-clubs .footer__link-collection__list {
    columns: unset;
  }
}
@media screen and (min-width: 48em) {
  .footer .sporting-clubs {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  @supports (display: grid) {
    .footer .sporting-clubs {
      flex-basis: auto;
      max-width: unset;
      grid-row: 2/4;
    }
  }
}
@media screen and (min-width: 62em) {
  .footer .sporting-clubs {
    background-color: transparent;
  }
  @supports (display: grid) {
    .footer .sporting-clubs {
      grid-column: 3/4;
      grid-row: 1/3;
    }
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    .footer .corporate-wear {
      grid-column: 1/2;
      grid-row: 2/3;
    }
  }
}
.footer__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.75);
  line-height: normal;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}
.footer__link:hover, .footer__link:focus {
  color: #ffffff;
}
.footer__link svg {
  width: 36px;
  height: 36px;
  margin-right: 21px;
}
.footer__link svg path {
  stroke: rgba(255, 255, 255, 0.75);
}
.footer__link:last-child {
  margin-bottom: 0;
}
.footer__logos {
  display: flex;
  margin-top: 9px;
  margin-bottom: 63px;
}
@media screen and (min-width: 35em) {
  .footer__logos {
    order: -1;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 62em) {
  .footer__logos {
    order: unset;
    margin-bottom: 1.5rem;
  }
}
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .footer__logos {
      flex-basis: auto;
      max-width: unset;
      grid-column: 2/4;
      grid-row: 1;
      justify-self: end;
    }
  }
  @media screen and (min-width: 62em) {
    .footer__logos {
      grid-column: 4/5;
      grid-row: 2;
      justify-self: start;
      align-self: end;
    }
  }
}
.footer__logos__img {
  height: 90px;
  width: auto;
  margin-right: 45px;
}
@media screen and (min-width: 35em) {
  .footer__logos__img {
    height: 72px;
    margin-right: 36px;
  }
}
@media screen and (min-width: 48em) {
  .footer__logos__img {
    height: 90px;
    margin-right: 45px;
  }
}
@media screen and (min-width: 62em) {
  .footer__logos__img {
    height: 72px;
    margin-right: 36px;
  }
}
.footer__logos__img:last-child {
  margin-right: 0;
}
.footer__credits {
  border-top: 1px solid rgba(240, 244, 246, 0.15);
  padding-top: 36px;
  padding-bottom: 36px;
}
@media screen and (min-width: 48em) {
  .footer__credits {
    padding-top: 27px;
    padding-bottom: 27px;
  }
}
.footer__credits .container {
  display: flex;
  flex-wrap: wrap;
}
.footer__credits__content {
  flex: 1 1 auto;
  max-width: calc(100% - 36px);
  text-transform: uppercase;
  letter-spacing: 0.1666em;
  font-size: 1.2rem;
}
.footer__credits__content a {
  display: block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.footer__credits__content a:hover, .footer__credits__content a:focus {
  color: #ffffff;
}
@media screen and (min-width: 48em) {
  .footer__credits__content a {
    display: inline;
    margin-right: 45px;
  }
}
.footer__credits__copyright {
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 62em) {
  .footer__credits__copyright {
    display: inline;
    margin-right: 45px;
  }
}
.footer__credits__built-by {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/* ---- lazy image loading ---- */
.filtered-list .item-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.filtered-list.unfiltered .item-hidden, .filtered-list.filtered .filtered-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.filtered-list.unfiltered .filter-listing__item--animate {
  animation: fadeIn 300ms ease;
  animation-fill-mode: forwards;
}
.filtered-list.filtered .filter-listing__item--filtered-pre-visible {
  opacity: 0;
}
.filtered-list.unfiltered .filter-listing__item--animate-grow, .filtered-list.filtered .filter-listing__item--filtered-visible {
  animation: growInFade 350ms ease;
  animation-fill-mode: forwards;
}

/* ------------------------------------------------------
**** Category lists
------------------------------------------------------ */
.filter-list__filters {
  padding: 0;
  margin-bottom: 1.5rem;
  text-align: center;
}

.field-element.filter-list__dropdown {
  margin-bottom: 35px;
}
@media screen and (min-width: 35em) {
  .field-element.filter-list__dropdown {
    margin-bottom: 0;
  }
}

.js-filters-list .js-filter-category-btn__select {
  margin-bottom: 24px;
}

/* Hides the list if js is working, defaults to working list if not */
@media screen and (max-width: 34.9375em) {
  .js .js-filters-list .filter-list__filters {
    display: none;
  }
}
@media screen and (min-width: 35em) {
  .filter-list__filters .button {
    border-radius: 0;
    margin: 2px;
  }
  .filter-list__filters .button.button-grey {
    background-color: #9c9c9c;
    border-color: #9c9c9c;
    color: #ffffff;
  }
  .filter-list__filters .button.button-grey:hover, .filter-list__filters .button.button-grey:focus {
    background-color: #0D0800;
    border-color: #0D0800;
    color: #1B244A;
  }
  .filter-list__filters__category-group {
    margin: 0 -3px -6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .filter-list__filters__category-group .filter-list__button {
    display: inline-block;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    background: #1B244A;
    text-decoration: none;
    border-radius: 0;
    border: none;
    outline: none;
    opacity: 1;
    position: relative;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
    padding: 12px 27px;
    padding: 6px 12px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 6px;
  }
  .filter-list__filters__category-group .filter-list__button:hover, .filter-list__filters__category-group .filter-list__button:focus, .filter-list__filters__category-group .filter-list__button:active {
    background-color: #0d1225;
    color: #ffffff;
  }
  .filter-list__filters__category-group .filter-list__button svg path {
    fill: #ffffff;
  }
  .filter-list__filters__category-group .filter-list__button:after {
    content: " >";
  }
  .filter-list__filters__category-group .filter-list__button:after {
    content: none;
  }
  .filter-list__filters__category-group .filter-list__button.button-grey {
    background-color: #E9EFF2;
    color: #1D1E26;
  }

  .js-filter-category-btn__select {
    display: none !important;
  }
}
.filter-list__filters__title {
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-top: 0;
}
@media screen and (min-width: 62em) {
  .filter-list__filters__title {
    font-size: 1.5rem;
  }
}

* + .filter-list__filters__title {
  margin-top: 0.75rem;
}

@media screen and (min-width: 35em) {
  .filter-list__filters__item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 62em) {
  .filter-list__filters__item {
    flex-grow: 0;
  }
}
.filter-list__filters__item .button {
  display: block;
  width: calc(100% - 4px);
}

/* Grow in fade */
@-webkit-keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.quote-form {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (min-width: 35em) {
  .quote-form .quote-form__header.field-element {
    align-items: flex-start;
  }
}
.quote-form__title {
  display: block;
  font-size: 2.25rem;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0.5em;
  color: #1D1E26;
}
@media screen and (min-width: 30em) {
  .quote-form__title {
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 48em) {
  .quote-form__title {
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 62em) {
  .quote-form__title {
    font-size: 3.6rem;
  }
}
.quote-form__back-button {
  margin-top: -1.5em;
  margin-bottom: 2.5em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  background-color: #6A6D73;
  color: #ffffff;
}
.quote-form__back-button:hover, .quote-form__back-button:focus {
  background-color: #4A4B51;
  color: #ffffff;
}
.quote-form__back-button svg path {
  fill: #ffffff;
}
.quote-form__back-button:hover, .quote-form__back-button:focus, .quote-form__back-button:active {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 35em) {
  .quote-form__back-button {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.quote-form__back-button:before {
  content: "< ";
}
.quote-form__back-button:after {
  content: none;
}
.quote-form .form__header {
  margin-top: 3rem;
}
@media screen and (min-width: 48em) {
  .quote-form .form__header {
    padding-left: 300px;
  }
}
@media screen and (min-width: 62em) {
  .quote-form .form__header {
    padding-left: calc(50% - 90px);
  }
}
@media screen and (min-width: 75em) {
  .quote-form .form__header {
    padding-left: 400px;
  }
}
.quote-form .product__field {
  margin-bottom: 45px;
}
@media screen and (min-width: 48em) {
  .quote-form .product__field {
    margin-bottom: 63px;
  }
}
@media screen and (min-width: 35em) {
  .quote-form .field-element {
    display: flex;
    align-items: center;
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media screen and (min-width: 35em) {
  .quote-form .field-element > * {
    padding-left: 5px;
    padding-right: 5px;
    flex: 1 1 auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 35em) {
  .quote-form .field-element > *:first-child {
    flex: 0 0 33.3333%;
  }
}
@media screen and (min-width: 48em) {
  .quote-form .field-element > *:first-child {
    flex: 0 0 300px;
  }
}
@media screen and (min-width: 62em) {
  .quote-form .field-element > *:first-child {
    flex: 0 0 calc(50% - 90px);
  }
}
@media screen and (min-width: 75em) {
  .quote-form .field-element > *:first-child {
    flex: 0 0 400px;
  }
}
@media screen and (min-width: 35em) {
  .quote-form .field-element .field-label {
    margin-bottom: 0;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
.quote-form .field-element .field-label .field-label__required {
  text-align: left;
}
.quote-form .field-element .field-label .instructions {
  flex: 0 0 100%;
  font-weight: 400;
  margin-bottom: 0;
}
.quote-form .field-element--button {
  display: flex;
}
.quote-form .field-element--button > *:last-child {
  flex: 1 1 100%;
}
.quote-form .field-element--button > *:last-child .button {
  display: block;
  width: 100%;
}
.quote-form .field-element--button .quote-form__back-button {
  margin-top: 0;
  margin-bottom: 0;
}
.tile-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 45px;
}
@media screen and (min-width: 48em) {
  .tile-list {
    gap: 36px;
  }
}
@media screen and (min-width: 62em) {
  .tile-list {
    gap: 45px;
  }
}
@media screen and (min-width: 75em) {
  .tile-list {
    flex-wrap: nowrap;
  }
}
.tile-list .tile {
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 48em) {
  .tile-list .tile {
    flex: 0 1 50%;
    max-width: calc(50% - 18px);
  }
}
@media screen and (min-width: 62em) {
  .tile-list .tile {
    max-width: calc(50% - 23px);
  }
}
@media screen and (min-width: 75em) {
  .tile-list .tile {
    flex: 0 1 33.333%;
    max-width: calc(33.333% - 15px);
  }
}

/* ---- top-heavy-list ---- */
/* To display a list of products nicely */
.top-heavy-list {
  width: 100%;
  list-style: none;
  padding: 20px 0 30px;
  margin: 0 -10px;
}
.top-heavy-list:before, .top-heavy-list:after {
  content: " ";
  display: table;
}
.top-heavy-list:after {
  clear: both;
}
.top-heavy-list > .top-heavy-list {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.top-heavy-list + .top-heavy-list {
  padding-top: 0;
}
.top-heavy-list__item {
  padding: 10px;
}

@media screen and (min-width: 35em) {
  .top-heavy-list__item {
    padding: 10px;
    width: 50%;
    float: left;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 62em) {
  /* 992px */
  .top-heavy-list__item {
    width: 33.3333%;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 87.5em) {
  .top-heavy-list__item {
    width: 25%;
  }
  .mainbar--narrow .top-heavy-list__item {
    width: 33.33333333%;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(4n+1) {
    clear: left;
  }
}
/* Flex code */
@supports (display: flex) {
  @media screen and (min-width: 35em) {
    .top-heavy-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: -10px;
      margin-right: -10px;
      width: calc(100% + 20px) .top-heavy-list __item;
      width-margin-right: auto;
      width-margin-left: auto;
      width-padding: 0 10px 20px;
      width-flex-basis: 50%;
      width-min-width: 50%;
      width-width: auto !important;
    }
    .top-heavy-list--five .top-heavy-list__item, .top-heavy-list__item:nth-child(-n+3) {
      flex-grow: 1;
    }
  }
  @media screen and (max-width: 61.9375em) {
    .top-heavy-list--three .top-heavy-list__item:first-child, .top-heavy-list--five .top-heavy-list__item:first-child, .top-heavy-list--seven .top-heavy-list__item:first-child, .top-heavy-list--odd .top-heavy-list__item:first-child {
      flex-basis: 100%;
    }
  }
  @media screen and (min-width: 62em) {
    /* 992px */
    .top-heavy-list {
      margin-left: -2%;
      margin-right: -2%;
      /*  grid with sidebar, stays only 3 columns  */
    }
    .top-heavy-list .top-heavy-list__item {
      padding: 2%;
      flex-basis: 25%;
      min-width: 25%;
    }
    .top-heavy-list--odd > .top-heavy-list__item, .mainbar--narrow .top-heavy-list > .top-heavy-list__item, .top-heavy-list--six > .top-heavy-list__item, .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+3) {
      flex-basis: 33.33333333%;
      min-width: 33.33333333%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(n+3) {
      flex-basis: 20%;
    }
    .mainbar--narrow .top-heavy-list--four > .top-heavy-list__item:nth-child(-n+4), .mainbar--narrow .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+4) {
      flex-basis: 50%;
    }
    .mainbar--narrow .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2), .mainbar--narrow .top-heavy-list--even > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
  }
}
.media-object__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.media-object__list:before, .media-object__list:after {
  content: " ";
  display: table;
}
.media-object__list:after {
  clear: both;
}

.media-object__item {
  padding: 10px 0;
}

.media-object__list:after {
  clear: both;
}

.card__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
@supports (display: grid) {
  .card__list {
    display: grid;
    grid-gap: 18px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
  @media screen and (min-width: 35em) {
    .card__list {
      grid-gap: 27px;
    }
  }
  @media screen and (min-width: 62em) {
    .card__list {
      grid-gap: 45px;
    }
  }
}

@media screen and (min-width: 48em) {
  .media-article {
    display: flex;
    flex-wrap: wrap;
  }
}
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .media-article {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      column-gap: 27px;
    }
  }
  @media screen and (min-width: 62em) {
    .media-article {
      column-gap: 45px;
    }
  }
}
.media-article__heading {
  display: block;
  line-height: normal;
  margin-bottom: 3.6rem;
}
@media screen and (min-width: 35em) {
  .media-article__heading {
    text-align: center;
  }
}
@media screen and (min-width: 48em) {
  .media-article__heading {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 62em) {
  .media-article__heading {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 87.5em) {
  .media-article__heading {
    margin-bottom: 27px;
    text-align: left;
    padding-left: 21px;
  }
}
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .media-article__heading {
      grid-column: span 2;
      grid-row: 1/2;
    }
  }
  @media screen and (min-width: 87.5em) {
    .media-article__heading {
      grid-column: 2/3;
    }
  }
}
@media screen and (min-width: 87.5em) {
  .media-article__title {
    font-size: 7.2rem;
  }
}
.media-article__media {
  background-color: #F0F4F6;
  margin-bottom: 21px;
}
@media screen and (min-width: 48em) {
  .media-article__media {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 62em) {
  .media-article__media {
    flex: 0 0 50%;
  }
}
@supports (display: grid) {
  @media screen and (min-width: 62em) {
    .media-article__media {
      grid-column: 1;
      grid-row: 2/3;
    }
  }
  @media screen and (min-width: 87.5em) {
    .media-article__media {
      grid-column: 1;
      grid-row: 1/3;
    }
  }
}
@media screen and (min-width: 62em) {
  .media-article__content {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
  }
  @supports (display: grid) {
    .media-article__content {
      grid-column: 2;
      grid-row: 2/3;
    }
  }
}
@media screen and (min-width: 87.5em) {
  .media-article__content {
    padding-left: 21px;
  }
}
@media screen and (min-width: 75em) {
  .media-article__content {
    justify-content: center;
    line-height: 1.8;
  }
}
@media screen and (min-width: 87.5em) {
  @supports (display: grid) {
    .media-article__content {
      grid-column: 2;
      grid-row: 2/3;
    }
  }
}
.media-article img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.media-article__cta {
  margin-bottom: 0;
}
@media screen and (min-width: 75em) {
  .media-article__cta {
    margin-top: 2.25rem;
  }
}
@media screen and (min-width: 87.5em) {
  .media-article__cta {
    margin-top: auto;
  }
}
.media-article__cta a {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
}
.media-article__cta a svg path {
  fill: #1B244A;
}
.media-article__cta a:hover, .media-article__cta a:focus, .media-article__cta a:active {
  color: #1D1E26;
}
.media-article__cta a:hover svg path, .media-article__cta a:focus svg path, .media-article__cta a:active svg path {
  fill: #1D1E26;
}
.media-article__cta a:after {
  content: " >";
}

@media screen and (min-width: 48em) {
  .js .js-animate-section .media-article__media {
    opacity: 0;
    transform: translate(-30px, 30px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .media-article__heading {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
}
@media screen and (min-width: 87.5em) {
  .js .js-animate-section .media-article__heading {
    transform: translate(30px, 30px);
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .media-article__content {
    opacity: 0;
    transform: translate(30px, 30px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .media-article__media {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .media-article__heading {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (min-width: 87.5em) {
  .js .js-animate-section.animate-section .media-article__heading {
    transform: translate(0, 0);
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .media-article__content {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* ---- Post Gallery ---- */
.posts-gallery {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
@media screen and (min-width: 48em) {
  .posts-gallery {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    .posts-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 36px;
    }
    .posts-gallery::before, .posts-gallery::after {
      content: none;
    }
  }
}
@media screen and (min-width: 87.5em) {
  @supports (display: grid) {
    .posts-gallery {
      grid-gap: 45px;
    }
  }
}

.post-gallery__item {
  margin-bottom: 4.5rem;
}
@media screen and (min-width: 48em) {
  .post-gallery__item {
    flex: 0 1 33.333%;
    max-width: calc(33.333% - 12px);
    margin-right: 18px;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__item {
    width: calc(50% - 18px);
    margin-left: 17px;
    float: right;
  }
  @supports (display: grid) {
    .post-gallery__item {
      width: 100%;
      flex: 0 1 100%;
      max-width: 100%;
      margin: 0;
    }
  }
}
@media screen and (min-width: 48em) {
  .post-gallery__item:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    .post-gallery__item--0 {
      grid-column: 1;
      grid-row: 1/4;
    }
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    .post-gallery__item--1 {
      grid-column: 2;
      grid-row: 1/2;
    }
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    .post-gallery__item--2 {
      grid-column: 2;
      grid-row: 2/3;
    }
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    .post-gallery__item--3 {
      grid-column: 2;
      grid-row: 3/4;
    }
  }
}
.post-gallery__inner {
  box-sizing: border-box;
  display: flex;
}
@media screen and (min-width: 48em) {
  .post-gallery__inner {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__inner {
    height: 100%;
  }
}
.post-gallery__image-link {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
  margin-right: 15px;
}
@media screen and (min-width: 35em) {
  .post-gallery__image-link {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}
@media screen and (min-width: 48em) {
  .post-gallery__image-link {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__image-link {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-bottom: 0;
    margin-right: 18px;
  }
}
@media screen and (min-width: 87.5em) {
  .post-gallery__image-link {
    flex: 0 0 25%;
    max-width: 25%;
    margin-right: 36px;
  }
}
.post-gallery__picture {
  display: block;
  width: 100%;
  height: 100%;
}
@supports (object-fit: cover) {
  .post-gallery__picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.post-gallery__content {
  flex: 1 1 auto;
  max-width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 35em) {
  .post-gallery__content {
    max-width: calc(66.666% - 15px);
  }
}
@media screen and (min-width: 48em) {
  .post-gallery__content {
    max-width: 100%;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__content {
    max-width: calc(66.666% - 18px);
  }
}
@media screen and (min-width: 87.5em) {
  .post-gallery__content {
    max-width: calc(75% - 36px);
  }
}
.post-gallery__excerpt {
  display: none;
}
@media screen and (min-width: 35em) {
  .post-gallery__excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-width: 48em) {
  .post-gallery__excerpt {
    display: none;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
}
.post-gallery__title {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  color: #1D1E26;
  font-size: 1.5rem;
  margin-bottom: 0.66em;
  margin-top: 0;
}
@media screen and (min-width: 75em) {
  .post-gallery__title {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 75em) {
  .post-gallery__title {
    font-size: 2.1rem;
  }
}
.post-gallery__title-link {
  text-decoration: none;
  color: #1D1E26;
}
.post-gallery__subtitle {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #BCAD98;
  display: none;
  margin-bottom: 0;
}
@media screen and (min-width: 62em) {
  .post-gallery__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.post-gallery__date {
  display: block;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
}
@media screen and (min-width: 62em) {
  .post-gallery__date {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 48em) {
  .post-gallery__date {
    display: none;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__date {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 35em) {
  .post-gallery__title {
    margin-bottom: 12px;
  }
}
.post-gallery__cta {
  margin-bottom: 0;
}
.post-gallery__cta a {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
}
.post-gallery__cta a svg path {
  fill: #1B244A;
}
.post-gallery__cta a:hover, .post-gallery__cta a:focus, .post-gallery__cta a:active {
  color: #1D1E26;
}
.post-gallery__cta a:hover svg path, .post-gallery__cta a:focus svg path, .post-gallery__cta a:active svg path {
  fill: #1D1E26;
}
.post-gallery__cta a:after {
  content: " >";
}
.post-gallery__cta .long-label {
  display: none;
}
@media screen and (min-width: 35em) {
  .post-gallery__cta .long-label {
    display: inline;
  }
}
@media screen and (min-width: 48em) {
  .post-gallery__cta .long-label {
    display: none;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__cta .long-label {
    display: inline;
  }
}

@media screen and (min-width: 48em) {
  .post-gallery__item--0 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__item--0 {
    width: calc(50% - 18px);
    margin-right: 17px;
    margin-left: 0;
    float: left;
  }
  @supports (display: grid) {
    .post-gallery__item--0 {
      width: 100%;
    }
  }
}
.post-gallery__item--0 .post-gallery__inner {
  flex-wrap: wrap;
}
@media screen and (min-width: 62em) {
  .post-gallery__item--0 .post-gallery__inner {
    height: auto;
  }
}
.post-gallery__item--0 .post-gallery__content {
  flex: 1 1 100%;
  max-width: 100%;
  display: block;
}
@media screen and (min-width: 62em) {
  .post-gallery__item--0 .post-gallery__content {
    width: 100%;
    float: unset;
    flex-basis: 100%;
    max-width: 100%;
  }
}
.post-gallery__item--0 .post-gallery__excerpt {
  display: block;
}
.post-gallery__item--0 .post-gallery__image-link {
  margin-bottom: 1.5rem;
  flex: 0 0 100%;
  max-width: 100%;
  margin-right: 0;
}
@media screen and (min-width: 35em) {
  .post-gallery__item--0 .post-gallery__image-link {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 35em) {
  .post-gallery__item--0 .post-gallery__picture {
    width: 100%;
    float: none;
  }
}
@media screen and (min-width: 62em) {
  .post-gallery__item--0 .post-gallery__picture {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
    height: auto;
  }
}
@media screen and (min-width: 35em) {
  .post-gallery__item--0 .post-gallery__subtitle {
    display: inline-block;
    transform: translateY(-50%);
  }
}
.post-gallery__item--0 .post-gallery__date {
  display: none;
}
@media screen and (min-width: 35em) {
  .post-gallery__item--0 .post-gallery__title {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 75em) {
  .post-gallery__item--0 .post-gallery__title {
    font-size: 2.1rem;
  }
}
.post-gallery__item--0 .post-gallery__cta .long-label {
  display: inline;
}

@media screen and (min-width: 48em) {
  .js .js-animate-section .post-gallery-header {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .post-gallery__item {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
  }
}
@media screen and (min-width: 62em) {
  .js .js-animate-section .post-gallery__item--0 {
    transform: translate(-30px, 30px);
  }
}
@media screen and (min-width: 62em) {
  .js .js-animate-section .post-gallery__item--1,
.js .js-animate-section .post-gallery__item--2,
.js .js-animate-section .post-gallery__item--3 {
    transform: translate(30px, 30px);
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .post-gallery-header {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .post-gallery__item {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (min-width: 62em) {
  .js .js-animate-section.animate-section .post-gallery__item {
    transform: translate(0, 0);
  }
}
.js .js-animate-section.animate-section .post-gallery__item--0 {
  transition-delay: 0ms;
}
.js .js-animate-section.animate-section .post-gallery__item--1 {
  transition-delay: 250ms;
}
.js .js-animate-section.animate-section .post-gallery__item--2 {
  transition-delay: 500ms;
}
.js .js-animate-section.animate-section .post-gallery__item--3 {
  transition-delay: 750ms;
}

.listing-page__introduction {
  margin-bottom: 2.7rem;
}
@media screen and (min-width: 35em) {
  .listing-page__introduction {
    margin-bottom: 3.6rem;
    text-align: center;
  }
}
@media screen and (min-width: 62em) {
  .listing-page__introduction {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 62em) {
  .listing-page__introduction.long {
    columns: 2;
    text-align: left;
    column-gap: 40px;
  }
  .listing-page__introduction.long p {
    break-inside: avoid;
  }
}
@media screen and (min-width: 75em) {
  .listing-page__introduction.long {
    column-gap: 56px;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 100em) {
  .listing-page__introduction.long {
    column-gap: 64px;
    margin-bottom: 7.2rem;
  }
}
@media screen and (min-width: 62em) {
  .listing-page__introduction.short {
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;
  }
}

/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */