
/* ---- Post Gallery ---- */
.posts-gallery {
    padding: 0;
    margin: 0;
    list-style-type: none;

    @include screen-768 {
        display: flex;
        flex-wrap: wrap;
    }

    @include screen-992 {
        @supports(display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            grid-gap: 36px;

            &::before,
            &::after {
                content: none;
            }
        }
    }

    @include screen-1400 {
        @supports(display: grid) {
            grid-gap: 45px;
        }
    }
}

.post-gallery {
    &__item {
        margin-bottom: $fs-body*3;

        @include screen-768 {
            flex: 0 1 33.333%;
            max-width: calc(33.333% - 12px);
            margin-right: 18px;
        }

        @include screen-992 {
            width: calc(50% - 18px);
            margin-left: 17px;
            float: right;

            @supports(display: grid) {
                width: 100%;
                flex: 0 1 100%;
                max-width: 100%;
                margin: 0;
            }
        }

        &:last-child {
            @include screen-768 {
                margin-right: 0;
            }
        }

        &--0 {
            @include screen-992 {
                @supports(display: grid) {
                    grid-column: 1;
                    grid-row: 1 / 4;
                }
            }
        }

        &--1 {
            @include screen-992 {
                @supports(display: grid) {
                    grid-column: 2;
                    grid-row: 1 / 2;
                }
            }
        }

        &--2 {
            @include screen-992 {
                @supports(display: grid) {
                    grid-column: 2;
                    grid-row: 2 / 3;
                }
            }
        }

        &--3 {
            @include screen-992 {
                @supports(display: grid) {
                    grid-column: 2;
                    grid-row: 3 / 4;
                }
            }
        }
    }

    &__inner {
        box-sizing: border-box;
        display: flex;

        @include screen-768 {
            flex-wrap: wrap;
        }

        @include screen-992 {
            height: 100%;
        }
    }

    &__image-link {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
        margin-right: 15px;

        @include screen-560 {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }

        @include screen-768 {
            flex: 0 0 100%;
            max-width: 100%;
            margin-right: 0;
            margin-bottom: $fs-body;
        }

        @include screen-992 {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            margin-bottom: 0;
            margin-right: 18px;
        }

        @include screen-1400 {
            flex: 0 0 25%;
            max-width: 25%;
            margin-right: 36px;
        }
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;

        img {
            @supports(object-fit: cover) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__content {
        flex: 1 1 auto;
        max-width: calc(50% - 15px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include screen-560 {
            max-width: calc(66.666% - 15px);
        }

        @include screen-768 {
            max-width: 100%;
        }

        @include screen-992 {
            max-width: calc(66.666% - 18px);
        }

        @include screen-1400 {
            max-width: calc(75% - 36px);
        }
    }

    &__excerpt {
        display: none;

        @include screen-560 {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include screen-768 {
            display: none;
        }

        @include screen-992 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
        }
    }

    &__title {
        font-family: $primary-font;
        color: $color-primary;
        font-size: $fs-body;
        margin-bottom: 0.66em;
        margin-top: 0;

        @include screen-1200 {
            font-size: $fs-body*1.2;
        }

        @include screen-1200 {
            font-size: $fs-body*1.4;
        }
    }

    &__title-link {
        text-decoration: none;
        color: $color-primary;
    }

    &__subtitle {
       @include subtitleBlock($color-03);
       display: none;
       margin-bottom: 0;
    }

    &__date {
        display: block;
        @include subtitle;
        margin-bottom: $fs-body*0.5;

        @include screen-768 {
            display: none;
        }

        @include screen-992 {
            font-size: $fs-small;
        }
    }

    &__title {
        @include screen-560 {
            margin-bottom: 12px;
        }
    }

    &__cta {
        margin-bottom: 0;

        a {
            @include text-button($color-00, $color-primary);
        }

        .long-label {
            display: none;

            @include screen-560 {
                display: inline;
            }

            @include screen-768 {
                display: none;
            }

            @include screen-992 {
                display: inline;
            }
        }
    }
}

.post-gallery__item--0 {
    @include screen-768 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
    }

    @include screen-992 {
        width: calc(50% - 18px);
        margin-right: 17px;
        margin-left: 0;
        float: left;

        @supports(display: grid) {
            width: 100%;
        }
    }

    .post-gallery__inner {
        flex-wrap: wrap;

        @include screen-992 {
            height: auto;
        }
    }

    .post-gallery__content {
        flex: 1 1 100%;
        max-width: 100%;
        display: block;

        @include screen-992 {
            width: 100%;
            float: unset;
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .post-gallery__excerpt {
        display: block;
    }

    .post-gallery__image-link {
        margin-bottom: $fs-body;
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0;

        @include screen-560 {
            margin-bottom: 0;
        }
    }

    .post-gallery__picture {
        @include screen-560 {
            width: 100%;
            float: none;
        }

        @include screen-992 {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
            height: auto;
        }
    }

    .post-gallery__subtitle {
        @include screen-560 {
            display: inline-block;
            transform: translateY(-50%);
        }
    }

    .post-gallery__date {
        display: none;
    }

    .post-gallery__title {
        @include screen-560 {
            font-size: $fs-body*1.2;
        }

        @include screen-1200 {
            font-size: $fs-body*1.4;
        }
    }

    .post-gallery__cta {
        .long-label {
            display: inline;
        }
    }
}


.js .js-animate-section {
    .post-gallery-header {
        @include screen-768 {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 350ms ease-in-out,
                transform 350ms ease-in-out;
        }
    }

    .post-gallery__item {
        @include screen-768 {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 350ms ease-in-out,
            transform 350ms ease-in-out;
        }
    }

    .post-gallery__item--0 {
        @include screen-992 {
            transform: translate(-30px, 30px);
        }
    }

    .post-gallery__item--1,
    .post-gallery__item--2,
    .post-gallery__item--3 {
        @include screen-992 {
            transform: translate(30px, 30px);
        }
    }

    &.animate-section {
        .post-gallery-header {
            @include screen-768 {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .post-gallery__item {
            @include screen-768 {
                opacity: 1;
                transform: translateY(0);
            }

            @include screen-992 {
                transform: translate(0, 0);
            }
        }

        @for $i from 0 through 3 {
            .post-gallery__item--#{$i} {
                transition-delay: $i * 250ms;
            }
        }
    }
}
