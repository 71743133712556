.slider {
    position: relative;

    &__track {
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }
    }
}

.slider__dots {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: none;

    @media screen and (min-width: 25em) {
        display: flex;
    }

    button {
        background: none;
        border: none;
        outline: none;
        white-space: normal;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
    }

    a,
    button {
        padding: 12px;
        position: relative;

        span {
            @include vis-hidden;
        }

        &:before {
            content: "";
            background-color: transparent;
            border: 2px solid $color-grey-04;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition:
                background-color 200ms ease,
                box-shadow 200ms ease;
        }

        &:hover:before {
            background-color: $color-grey-06;
            border-color: $color-grey-06;
        }

        &:focus:before {
            background-color: $color-grey-06;
            border-color: $color-grey-06;
        }

        &.active:before {
            background-color: $color-grey-06;
            border-color: $color-grey-06;
            transition-delay: 50ms;
        }
    }
}

.slider__arrows {
    position: absolute;
    top: calc(50% - 18px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    background: transparent;
    text-decoration: none;
    border-radius: 0;
    border: none;
    outline: none;
    opacity: 1;
    padding: 0;
    transition:
        background-color 250ms ease-in-out,
        color 250ms ease-in-out,
        border-color 250ms ease-in-out;
    width: 36px;
    height: 36px;

    @include screen-480 {
        width: 54px;
        height: 54px;
        top: calc(50% - 27px);
    }

    &.previous {
        right: 100%;

        &:hover {
            svg {
                transform: scale(1.33) translateX(-3px);
            }
        }
    }

    &.next {
        left: 100%;

        &:hover {
            svg {
                transform: scale(1.33) translateX(3px);
            }
        }
    }

    &:focus svg path {
        fill: $color-black;
    }

    * {
        pointer-events: none;
    }

    svg {
        transform: scale(1) translateX(0);
        transition: transform 250ms ease-in-out;

        @include screen-480 {
            width: 10px;
            height: 12px;
        }
    }
}

.slider-clean {
    position: relative;
}

[aria-label="gallery"]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px; /* compensates for 2px border */
}

.slider__item img {
    object-fit: cover;
}
