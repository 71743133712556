
/* Tabs layout */
.tabs-list {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;
    list-style-type: none;

    @media screen and (min-width: 30em) {/* 480px */
        margin-bottom: 8px;
    }
}

.tabs-list__item {
    display: inline-block;
    margin-right: 16px;

    @media screen and (min-width: 30em) {/* 480px */
        margin-right: 24px;
    }
}

.tabs-list__button {
    position: relative;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    outline: none;
    font-family: inherit;
    padding: 0;
    line-height: 1.2;

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: left;
    text-decoration: none;

    color: $color-grey-06;
    font-family: $accent-font;
    font-size: $fs-body*1.4;
    font-weight: $fw-regular;

    @include screen-768 {
        font-size: $fs-body*2;
    }

    span {
        pointer-events: none;
    }
}

.tab-btn__chevron-icon,
.tab-btn__title {
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
}

.tab-btn__chevron-icon {
    position: relative;
    margin-top: 0;
    height: 12px;
    width: 20px;
    margin-left: 4px;

    @include screen-768 {
        margin-left: 8px;
    }
}

.tab-btn__chevron-icon:before,
.tab-btn__chevron-icon:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 12px;
    height: 2px;
    background-color: $color-grey-06;
}

.tab-btn__chevron-icon:before {
    transform: rotate(45deg);
    left: 0;
}

.tab-btn__chevron-icon:after {
    transform: rotate(-45deg);
    right: 0;
}

.tab-btn__title {
    margin-top: 0;
}

/* -- Hover styles -- */
.tabs-list__button:hover,
.tabs-list__button:focus {
    .tab-btn__title {
        color: $color-black;
    }

    .tab-btn__chevron-icon:before,
    .tab-btn__chevron-icon:after {
        background-color: $color-black;
    }
}

/* -- Active tab styles -- */
.tabs-list__item--active {
    .tabs-list__button {
        color: $color-primary;
    }

    .tab-btn__chevron-icon:before,
    .tab-btn__chevron-icon:after {
        background-color: $color-primary;
    }

    // ensure there's no hover state on the active tab
    .tabs-list__button:hover,
    .tabs-list__button:focus {
        .tab-btn__title {
            color: lighten($color-00, 10%);
        }

        .tab-btn__chevron-icon:before,
        .tab-btn__chevron-icon:after {
            background-color: lighten($color-00, 10%);
        }
    }
}


/* ---- The tab content ---- */
.tab {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.tab.tab--active {
    clip: auto;
    height: auto;
    margin: auto;
    overflow: visible;
    position: relative;
    width: auto;
    z-index: 1;
}

@media only screen and (min-width: 62em) { /* 992px */
    .tab.tab--active {
        -webkit-animation: fadeInUp 200ms ease;
        animation: fadeInUp 200ms ease;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
}
