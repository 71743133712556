
.footer {
    background: $color-primary;
    color: $color-white;
    padding-bottom: 0;

    .footer__container {
        display: flex;
        flex-wrap: wrap;

        @supports(display: grid) {
            @include screen-768 {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(4, auto);
                column-gap: $fs-body;
            }

            @include screen-992 {
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(3, auto);
                column-gap: 36px;
            }
        }

        @include screen-992 {
            padding-bottom: 63px;
        }

        @include screen-1400 {
            padding-bottom: 81px;
        }
    }

    &__contact {
        order: -1;
        margin-bottom: 63px;
        flex: 1 1 100%;
        max-width: 100%;

        @include screen-560 {
            order: -2;
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include screen-768 {
            @supports(display: grid) {
                flex-basis: auto;
                max-width: unset;
                grid-column: 1 / 2;
            }
        }

        @include screen-992 {
            order: unset;
            margin-bottom: 0;

            @supports(display: grid) {
                grid-column: 4 / 5;
                grid-row: 1;
            }
        }
    }

    &__link-collection {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 36px;

        @include screen-560 {
            flex-basis: 50%;
            max-width: 50%;
        }

        @include screen-768 {
            flex-basis: 33.333%;
            max-width: 33.333%;

            @supports(display: grid) {
                flex-basis: auto;
                max-width: unset;
            }
        }

        @include screen-992 {
            flex: 1 1 auto;
            max-width: 100%;
            margin-bottom: 0;
        }

        &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            columns: 2;

            @include screen-560 {
                columns: unset;
            }

            @supports(display: grid) {
                columns: unset;
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: $fs-body;

                @include screen-560 {
                    display: block;
                }
            }
        }

        &__title {
            display: block;
            color: $color-white;
            @include subtitle;
            font-size: $fs-body;
        }

        a {
            text-decoration: none;
            color: rgba($color-white, 0.75);
            display: block;
            line-height: normal;
            padding-top: 0.5em;
            padding-bottom: 0.5em;

            &:hover,
            &:focus {
                color: $color-white;
            }

            &[title~="activities"] {
                grid-column: 1 / 3;
            }
        }
    }

    .baby-wear-accessories {
        @include screen-768 {
            flex-basis: 66.666%;
            max-width: 66.666%;
            order: 1;

            @supports(display: grid) {
                flex-basis: auto;
                max-width: unset;
            }
        }

        @include screen-992 {
            @supports(display: grid) {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
            }
        }

        .footer__link-collection__list {
            columns: unset;

            @supports(display: grid) {
                display: block;
            }
        }
}

    .sporting-clubs {
        flex: 1 1 auto;
        max-width: 100%;

        .footer__link-collection__list {
            columns: 2;

            @include screen-768 {
                columns: unset;
            }
        }

        @include screen-768 {
            flex: 0 0 33.333%;
            max-width: 33.333%;

            @supports(display: grid) {
                flex-basis: auto;
                max-width: unset;
                grid-row: 2 / 4;
            }
        }

        @include screen-992 {
            // comment
            background-color: transparent;

            @supports(display: grid) {
                grid-column: 3 / 4;
                grid-row: 1 / 3;
            }
        }
    }

    .corporate-wear {
        @include screen-992 {
            @supports(display: grid) {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: rgba($color-white, 0.75);
        line-height: normal;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        margin-bottom: 1em;

        &:hover,
        &:focus {
            color: $color-white;
        }

        svg {
            width: 36px;
            height: 36px;
            margin-right: 21px;

            path {
                stroke: rgba($color-white, 0.75);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__logos {
        display: flex;
        margin-top: 9px;
        margin-bottom: 63px;

        @include screen-560 {
            order: -1;
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include screen-992 {
            order: unset;
            margin-bottom: $fs-body;
        }

        @supports(display: grid) {
            @include screen-768 {
                flex-basis: auto;
                max-width: unset;
                grid-column: 2 / 4;
                grid-row: 1;
                justify-self: end;
            }

            @include screen-992 {
                grid-column: 4 / 5;
                grid-row: 2;
                justify-self: start;
                align-self: end;
            }
        }

        &__img {
            height: 90px;
            width: auto;
            margin-right: 45px;

            @include screen-560 {
                height: 72px;
                margin-right: 36px;
            }

            @include screen-768 {
                height: 90px;
                margin-right: 45px;
            }

            @include screen-992 {
                height: 72px;
                margin-right: 36px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__credits {
        border-top: 1px solid rgba($color-grey-01, 0.15);
        padding-top: 36px;
        padding-bottom: 36px;

        @include screen-768 {
            padding-top: 27px;
            padding-bottom: 27px;
        }

        .container {
            display: flex;
            flex-wrap: wrap;
        }

        &__content {
            flex: 1 1 auto;
            max-width: calc(100% - 36px);
            text-transform: uppercase;
            letter-spacing: 0.1666em;
            font-size: $fs-small;

            a {
                display: block;
                text-decoration: none;
                color: rgba($color-white, 0.5);
                padding-top: 0.5em;
                padding-bottom: 0.5em;

                &:hover,
                &:focus {
                    color: $color-white;
                }

                @include screen-768 {
                    display: inline;
                    margin-right: 45px;
                }
            }
        }

        &__copyright {
            margin-bottom: 0.5em;
            color: rgba($color-white, 0.5);

            @include screen-992 {
                display: inline;
                margin-right: 45px;
            }
        }

        &__built-by {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}
