.testimonials__slider {
	position: relative;
	margin: 0 auto;
	width: 88vw;

	@include screen-480 {
		width: 92vw;
	}

	@include screen-768 {
		width: calc(100% - 112px);
	}

	@include screen-1200 {
		width: calc(100% - 8vw);
	}

	.splide__track {
		overflow: visible;

		@include screen-768 {
			overflow: hidden;
		}
	}
}

.splide__pagination {
	display: none;
	justify-content: flex-start;
	padding: 0;

	@include screen-768 {
		justify-content: center;
		margin-bottom: 40px;
	}

	button {
		padding: 12px;
		position: relative;
		background: none;
		border: none;
		outline: none;

		span {
			@include vis-hidden;
		}

		&:before {
			content: "";
			background-color: rgba($color-grey-04, 0.5);
			height: 8px;
			width: 8px;
			border-radius: 4px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover:before {
			background-color: $color-grey-04;
		}

		&:focus:before {
			background-color: $color-grey-04;
		}

		&.is-active:before {
			background-color: $color-03;
			transition-delay: 50ms;
		}
	}
}

.splide__list {
	@include screen-560 {
		align-items: center;
	}

	@include screen-768 {
		align-items: stretch;
	}
}

.testimonials__arrow {
	position: absolute;
	top: calc(50% - 25px);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0;
	white-space: normal;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	color: $color-white;
	background: transparent;
	text-decoration: none;
	border-radius: 0;
	border: none;
	outline: none;
	opacity: 1;
	padding: 0;
	transition:
		background-color 250ms ease-in-out,
		color 250ms ease-in-out,
		border-color 250ms ease-in-out;
	width: 6vw;
	height: 40px;
	z-index: 3;

	@include screen-480 {
		width: 56px;
		height: 56px;
	}

	&.splide__arrow--prev {
		right: 100%;

		@include screen-480 {
			justify-content: flex-end;
		}
	}

	&.splide__arrow--next {
		left: 100%;

		@include screen-480 {
			justify-content: flex-start;
		}
	}

	* {
		pointer-events: none;
	}

	svg {
		@include screen-480 {
			width: 10px;
			height: 13px;
		}
	}
}

.testimonial {
	&__item {
		width: 100%;
		flex-shrink: 0;
		scroll-snap-align: start;

		@include screen-560 {
			display: flex;
			flex-direction: column;
			text-align: center;
			padding: 18px;
		}

		@include screen-768 {
			padding: 36px;
		}

		@include screen-992 {
			flex: 0 0 33.333%;
			padding: 36px 0;
		}
	}

	&__box {
		display: block;
		background-color: $color-white;
		position: relative;
		padding: 27px 0;

		@include screen-560 {
			position: static;
			padding: 27px;
			box-shadow: 0 2px 36px rgba($color-black, 0.15), 0 2px 18px rgba($color-black, 0.15);
			border-radius: 3px;
		}

		@include screen-768 {
			padding: 45px;
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			width: 45px;
			left: 0;
			height: 3px;
			background-color: $color-grey-04;
			border-radius: 3px;

			@include screen-560 {
				content: none;
			}
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}

	}

	&__quote {
		font-size: $fs-body;
		color: $color-grey-06;
		transition: opacity 400ms 25ms ease-in-out,
			transform 400ms 25ms ease-in-out;

		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;

		line-height: 1.5;
		max-height: calc(6 * 1.5em);
	}

	&__attribution {
		font-family: $accent-font;
		font-size: $fs-body;
		color: $color-01;
		margin-top: 0;
		transition: opacity 400ms 200ms ease,
			transform 400ms 200ms ease;

		@include screen-560 {
			font-size: $fs-body*1.2;
		}

		@include screen-992 {
			font-size: $fs-body*1.4;
		}
	}

	&__job {
		font-family: $accent-font;
		font-size: 0.9em;
		color: $color-grey-06;
		margin-bottom: 0;
	}

	&__cta {
		margin-top: $fs-body;
		margin-bottom: 0;

		a {
            @include text-button($color-00, $color-primary);
        }
	}
}


.js {
	.testimonial__box {
		transition:
			opacity 200ms ease,
			transform 300ms ease;
		position: relative;
		transform: scale(0.8);
	}

	.testimonial__item.is-active .testimonial__box {
		@include screen-768 {
			transform: scale(1);
			z-index: 1;
		}
	}
}


.google-rating {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;

	svg	{
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__subtitle {
		flex: 0 0 100%;
		@include subtitle;
	}
}

.testimonial-section-header {
	margin-bottom: 27px;
}


.js .js-animate-section {
    .testimonial-section-header,
	.google-rating,
	.testimonials__slider {
        @include screen-768 {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 300ms ease-in-out,
                transform 300ms ease-in-out;
        }
    }

	.google-rating {
        @include screen-768 {
           transition-delay: 200ms;
        }
    }

    .testimonials__slider {
        @include screen-768 {
			transition-delay: 400ms;
        }
    }

    &.animate-section {
        .testimonial-section-header,
		.google-rating,
		.testimonials__slider {
            @include screen-768 {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
